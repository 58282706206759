import axios from 'axios';

const getAllEmails = async (token) => {
  try {
    const allEmailsRes = await axios.get('/Emails/all', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    console.log('all emails res: ', allEmailsRes);
    return allEmailsRes;
  } catch (error) {
    console.log(error);
  }
};

export default getAllEmails;
