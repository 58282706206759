/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import UesrContext from '../../contexts/user-context/UserProvider';
import UploadAboutFile from './UploadAboutFile';
import addAboutUs from '../../apis/about-us-api/addAboutUs';
import AboutContext from '../../contexts/aboutus-context/AboutUsProvider';
import './AboutForm.scss';

const defaultValues = {
  name_ar: '',
  name_en: '',
  desc_ar: '',
  desc_en: '',
  image: null
};
const AboutUsForm = () => {
  const [descArEditorState, setDescArEditorState] = useState(EditorState.createEmpty());
  const [descEnEditorState, setDescEnEditorState] = useState(EditorState.createEmpty());

  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setAboutModalOpened,
    allAboutData,
    setSelectedAboutId,
    selectedAboutId,
    selectedAbout,
    setSelectedAbout
  } = useContext(AboutContext);

  let schema;
  if (selectedAbout && selectedAboutId) {
    schema = Yup.object().shape({
      name_ar: Yup.string().required('من فضلك ادخل الخدمة باللغة العربية'),
      name_en: Yup.string().required('من فضلك ادخل الخدمة باللغة الانجليزية'),
      desc_ar: Yup.string().test('desc_ar', 'من فضلك ادخل تفاصيل الخدمة باللغة العربية', (v) => {
        let result = true;
        if (!v || v === '<p><br></p>') {
          result = false;
        }
        return result;
      }),
      desc_en: Yup.string().test('desc_en', 'من فضلك ادخل تفاصيل الخدمة باللغة الانجليزية', (v) => {
        let result = true;
        if (!v || v === '<p><br></p>') {
          result = false;
        }
        return result;
      })
    });
  } else {
    schema = Yup.object().shape({
      name_ar: Yup.string().required('من فضلك ادخل العنوان باللغة العربية'),
      name_en: Yup.string().required('من فضلك ادخل العنوان باللغة الانجليزية'),
      desc_ar: Yup.string().test('desc_ar', 'من فضلك ادخل التفاصيل باللغة العربية', (v) => {
        let result = true;
        if (!v || v === '<p><br></p>') {
          result = false;
        }
        return result;
      }),
      desc_en: Yup.string().test('desc_en', 'من فضلك ادخل التفاصيل باللغة الانجليزية', (v) => {
        let result = true;
        if (!v || v === '<p><br></p>') {
          result = false;
        }
        return result;
      }),
      image: Yup.mixed().required('من فضلك اختار الصورة')
    });
  }

  useEffect(() => {
    if (selectedAboutId) {
      const foundData = allAboutData.find((d) => d.key === selectedAboutId);
      if (foundData) setSelectedAbout(foundData);
    }
  }, [selectedAboutId]);

  const [urls, setUrls] = useState([]);

  useEffect(() => {
    if (selectedAbout?.image) {
      setUrls([selectedAbout?.image]);
    }
  }, [selectedAbout]);

  const {
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const onSubmit = async (values) => {
    try {
      if (!getValues()?.desc_ar || getValues()?.desc_ar === '<p><br></p>') {
        setError('desc_ar', {
          type: 'required',
          message: 'من فضلك ادخل التفاصيل باللغة العربية'
        });
      } else {
        // setError('desc_ar', )
        clearErrors('desc_ar');
      }

      let res;
      if (selectedAbout) {
        res = await addAboutUs(`/About/update/${selectedAbout.key}`, values, user?.token);
      } else {
        res = await addAboutUs('/About/create', values, user?.token);
      }
      // console.log('create service response: ', res);
      if (res?.status === 200 && res?.data?.status === 0) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشــاء التفاصيل',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.status === 1) {
        reset({ ...defaultValues });
        setValue('desc_ar', '');
        setValue('desc_en', '');
        setDescArEditorState(EditorState.createEmpty());
        setDescEnEditorState(EditorState.createEmpty());
        setAboutModalOpened(false);
        setSelectedAboutId('');
        setSelectedAbout(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم حفظ البيانات المدخلة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    setValue('desc_ar', stateToHTML(descArEditorState.getCurrentContent()));
  }, [stateToHTML(descArEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('desc_en', stateToHTML(descEnEditorState.getCurrentContent()));
  }, [stateToHTML(descEnEditorState.getCurrentContent())]);

  // handle initial values
  useEffect(() => {
    if (selectedAboutId && selectedAbout) {
      const { name_ar, name_en, desc_ar, desc_en } = selectedAbout;
      setValue('name_ar', name_ar);
      setValue('name_en', name_en);
      setValue('desc_ar', desc_ar);
      setValue('desc_en', desc_en);
      const contentBlockDescAr = htmlToDraft(desc_ar);
      if (contentBlockDescAr) {
        const contentState = ContentState.createFromBlockArray(contentBlockDescAr.contentBlocks);
        const editorStateDescAr = EditorState.createWithContent(contentState);
        setDescArEditorState(editorStateDescAr);
      }
      const contentBlockDescEn = htmlToDraft(desc_en);
      if (contentBlockDescEn) {
        const contentState = ContentState.createFromBlockArray(contentBlockDescEn.contentBlocks);
        const editorStateDescEn = EditorState.createWithContent(contentState);
        setDescEnEditorState(editorStateDescEn);
      }
    }
  }, [selectedAboutId, selectedAbout]);

  return (
    <Form className="about-us-form" form={form} layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <AntdTextField
          name="name_ar"
          type="text"
          label="الاســــم باللغة العربية"
          placeholder="الاســـــم..."
          errorMsg={errors?.name_ar?.message}
          validateStatus={errors?.name_ar ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
          formClassName="about-us-form"
        />
        <AntdTextField
          name="name_en"
          type="text"
          label="الاســـم باللغة الانجليزية"
          placeholder="الاســــم..."
          errorMsg={errors?.name_en?.message}
          validateStatus={errors?.name_en ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            التفاصيل باللغة العربية
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={descArEditorState}
            onEditorStateChange={(v) => {
              setDescArEditorState(v);
              if (!getValues()?.desc_ar || getValues()?.desc_ar === '<p><br></p>') {
                setError('desc_ar', {
                  type: 'required',
                  message: 'من فضلك ادخل التفاصيل باللغة العربية'
                });
              } else {
                // setError('desc_ar', )
                clearErrors('desc_ar');
              }
            }}
          />
          <p className="error-p">{errors?.desc_ar ? errors?.desc_ar.message : ''}</p>
        </div>

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            التفاصيل باللغة الانجليزية
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={descEnEditorState}
            // onBlur={(v) => console.log(v)}
            onEditorStateChange={(v) => {
              setDescEnEditorState(v);
              if (!getValues()?.desc_en || getValues()?.desc_en === '<p><br></p>') {
                setError('desc_en', {
                  type: 'required',
                  message: 'من فضلك ادخل التفاصيل باللغة الانجليزية'
                });
              } else {
                // setError('desc_en', )
                clearErrors('desc_en');
              }
            }}
          />
          <p className="error-p">{errors?.desc_en ? errors?.desc_en.message : ''}</p>
        </div>

        <UploadAboutFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="image"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              الصـــورة
            </>
          }
          urls={urls}
          setUrls={setUrls}
          errorMsg={errors?.image ? errors?.image?.message : ''}
        />

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          حفــظ
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AboutUsForm;
