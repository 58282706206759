import axios from 'axios';

const editProject = async (values, id, token) => {
  try {
    const {
      name_ar,
      name_en,
      desc_ar,
      desc_en,
      image_project,
      status,
      appleLink,
      webLink,
      androidLink,
      service_id
    } = values;

    console.log(image_project);

    const pickedValues = {
      name_ar,
      name_en,
      desc_ar,
      desc_en,
      status,
      // image: image_project[0],
      appleLink,
      webLink,
      androidLink,
      service_id
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }

    if (image_project && image_project[0]) {
      formData.append('image', image_project[0]);
    }

    const response = await axios.post('/Project/update/' + id, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    console.log('request project response: ', response);
    if (response.status === 200 && response.data.status === 1 && response.data.data) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default editProject;
