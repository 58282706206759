/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import AnswersContext from '../../contexts/answers-context/AnswersProvider';
import answersTableColumns from './answersTableColumns';
import getAllAnswers from '../../apis/answers/getAllAnswers';
const AnswersTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingAnswers,
    setLoadingAnswers,
    allAnswers,
    setAllAnswers,
    setAnswerModalOpened,
    setSelectedAsnswerId
  } = useContext(AnswersContext);
  const [answersDataSource, setAnswerssDataSource] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getAnswers = async () => {
      try {
        setLoadingAnswers(true);
        const getAnswersRes = await getAllAnswers(user?.token);
        console.log('answers: ', getAnswersRes);
        if (getAnswersRes.status === 200 && getAnswersRes?.data?.data) {
          if (isMounted) {
            setAllAnswers(getAnswersRes.data.data);
            setLoadingAnswers(false);
          }
        }
      } catch (error) {
        setLoadingAnswers(false);
        console.log(error);
      }
    };
    getAnswers();

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;
    if (allAnswers) {
      const constructName = (fName, lName) => {
        if (!fName && lName) return lName;
        else if (!lName && fName) return fName;
        else if (!fName && !lName) return '';
        else return fName + ' ' + lName;
      };

      const mappedAnswers = allAnswers.map((obj, index) => ({
        key: obj.id,
        index: index + 1,
        name: constructName(obj.firstName, obj.lastName),
        phone: obj?.phone,
        projectDesc: obj?.projectDesc,
        cat: obj?.cat?.name,
        answers: obj?.answers,
        date: obj?.date
      }));

      if (isMounted) {
        setAnswerssDataSource(mappedAnswers);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allAnswers, allAnswers.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="answers-table"
      dataSource={answersDataSource}
      columns={answersTableColumns(
        user?.token,
        setFetchCount,
        setLoadingAnswers,
        setAnswerModalOpened,
        setSelectedAsnswerId
      )}
      loading={loadingAnswers}
      scroll={{ x: 1200 }}
    />
  );
};

export default AnswersTable;
