import React, { useContext, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Button, Form } from 'antd';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { EditOutlined, SendOutlined } from '@ant-design/icons';
import UesrContext from '../../contexts/user-context/UserProvider';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UploadProfileFile from './UploadProjectFile';
import { store } from 'react-notifications-component';
import updateProfile from '../../apis/profile-apis/updateProfile';

const schema = yup.object().shape({
  name: yup.string().required('الأسم مطلوب'),
  phone: yup
    .string()
    .required('من فضلك ادخل رقم الهاتف')
    .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
  email: yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
  image: yup.array().min(1, 'من فضلك اختار صورة الملف الشخصي')
});

const defaultValues = {
  name: '',
  phone: '',
  email: '',
  password: '',
  image: []
};

const ProfileForm = () => {
  const { user, setCurrentUser } = useContext(UesrContext);
  console.log('user: ', user);
  const [urls, setUrls] = useState([]);
  const {
    control,
    setValue,
    handleSubmit,
    register,
    watch,
    unregister,

    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const [form] = Form.useForm();
  const onSubmit = async (data) => {
    try {
      const fetchedData = await updateProfile(data, user?.token);
      if (!fetchedData) {
        store.addNotification({
          title: 'حدث خطأ ',
          message: fetchedData?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setCurrentUser({ ...user, ...fetchedData.data, token: user?.token });
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تعديل البيانات بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
      //   console.log(error);
    }
  };
  useEffect(() => {
    // console.log(user);
    if (user) {
      setValue('name', user.name);
      setValue('phone', user.phone);
      setValue('email', user.email);
      setValue('name', user.name);
      // convertPathToFile(user.image).then((file) => {
      //   setValue('image', [file]);
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        className="add-project-form">
        <UploadProfileFile
          urls={urls}
          setUrls={setUrls}
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="image"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          selectedProject={user}
          label={
            <>
              <EditOutlined />
              صورة المستخدم
            </>
          }
          errorMsg={errors?.image_project ? errors?.image_project?.message : ''}
        />
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الاسم بالعربيه..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="email"
            type="text"
            placeholder="البريد الألكتروني..."
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="phone"
            type="text"
            placeholder="رقم الهاتف..."
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="password"
            type="password"
            placeholder="الرقم السري..."
            errorMsg={errors?.password?.message}
            validateStatus={errors?.password ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div>
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            icon={<SendOutlined />}
            loading={isSubmitting}>
            تعديل
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ProfileForm;
