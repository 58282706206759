import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { store } from 'react-notifications-component';
import deleteAboutApi from '../../apis/about-us-api/deleteAbout';

const aboutColumns = (
  token,
  setFetchCount,
  setLoadingAboutData,
  setAboutModalOpened,
  setSelectedAboutId
) => {
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingAboutData(true);
      const res = await deleteAboutApi(key, token);
      if (res.status === 200 && res.data.status === 1) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingAboutData(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم الحذف بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingAboutData(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingAboutData(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const handleEditService = (key) => {
    setAboutModalOpened(true);
    setSelectedAboutId(key);
    console.log(key);
  };

  return [
    {
      title: '#',
      dataIndex: 'key',
      width: 62
    },
    {
      title: 'الاســم باللغة العربية',
      dataIndex: 'name_ar',
      width: 192
    },
    {
      title: 'الاســـم باللغة الانجليزية',
      dataIndex: 'name_en',
      width: 192
    },
    {
      title: 'الصــورة',
      dataIndex: 'image',
      width: 180,
      render: (_, record) => {
        return (
          <div className="service-img">
            <img src={record.image} alt={record.name_en} />
          </div>
        );
      }
    },
    {
      title: 'تعديل',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditService(record.key)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من الحذف؟</p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record.key)}>
            <Tooltip title="حذف">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default aboutColumns;
