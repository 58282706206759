/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Card, Form, Tooltip } from 'antd';
import { EditOutlined, SelectOutlined, PlusOutlined, DeleteTwoTone } from '@ant-design/icons';
import { store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
// import getCategories from '../../apis/category-apis/getCategories';
import AntdRadioGroup from '../../common/antd-form-components/AntdRadioGroup';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';
import createQuestion from '../../apis/questions/createQuestion';
import textFieldImg from '../../assets/imgs/questions/text-field.png';
import fileUploadImg from '../../assets/imgs/questions/file-upload.png';
import selectOptionImg from '../../assets/imgs/questions/select-option.png';
import radioBtnsImg from '../../assets/imgs/questions/radio-buttons.png';
import checkboxsImg from '../../assets/imgs/questions/check-boxs.png';
import textareaImg from '../../assets/imgs/questions/textarea-placeholder.png';

import './AddQuestionForm.scss';
import getAllServices from '../../apis/services/getAllServices';

const schema = Yup.object().shape({
  name: Yup.string().required('من فضلك ادخل السؤال!'),
  cat_id: Yup.string().required('من فظلك اختار الخدمة الخاص بالسؤال'),
  is_required: Yup.string().required('من فضلك اختار نوع الاجابة'),
  type: Yup.string().required('من فضلك اختار نوع السؤال'),
  chooses: Yup.array()
    // test select options and radio buttons
    .test('chooses', 'من فضلك اضف اجابة واحدة على الاقل', (v, context) => {
      let result = true;
      if (
        (!v || v?.length === 0) &&
        (context.from[0].value.type === '3' || context.from[0].value.type === '4')
      ) {
        result = false;
      }
      return result;
    })
    // test checkboxs
    .test('chooses', 'من فضلك اضف اجابتين على الاقل', (v, context) => {
      let result = true;
      if ((!v || v?.length <= 1) && context.from[0].value.type === '5') {
        result = false;
      }
      return result;
    })
    .of(
      Yup.object().shape({
        name: Yup.string().test(
          'chooses.index.name',
          'من فضلك ادخل الاجابة المقترحة',
          (v, context) => {
            // console.log('v: ', v);
            // console.log('context: ', context);
            let result = true;
            if (
              !v &&
              (context.from[1].value.type === '3' ||
                context.from[1].value.type === '4' ||
                context.from[1].value.type === '5')
            ) {
              result = false;
            }
            return result;
          }
        )
      })
    )
});

const defaultValues = {
  name: '',
  cat_id: '',
  type: '',
  is_required: ''
  // chooses: [{ name: '' }]
  // chooses: [{ name: '' }]
};
const AddQuestionForm = () => {
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setQuestionModalOpened,
    allQuestions,
    setSelectedQuestionId,
    selectedQuestionId,
    selectedQuestion,
    setSelectedQuestion
  } = useContext(QuestionsContext);

  useEffect(() => {
    if (selectedQuestionId) {
      const foundQuestion = allQuestions.find((ques) => ques.id === selectedQuestionId);
      if (foundQuestion) setSelectedQuestion(foundQuestion);
    }
  }, [selectedQuestionId]);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const {
    fields: choosesFieldes,
    append: appendChooseField,
    remove: removeChooseField
  } = useFieldArray({
    control,
    name: 'chooses'
  });
  const [form] = Form.useForm();

  // handle initial values
  useEffect(() => {
    if (selectedQuestion && selectedQuestionId) {
      const { name, cat_id, is_required, type, question_chooses } = selectedQuestion;
      const mappedChooses = question_chooses.map((q) => ({ name: q.name }));
      // setValue('name', name);
      // setValue('type', String(type));
      // setValue('cat_id', String(cat_id));
      // setValue('is_required', String(is_required));
      // setValue('chooses', mappedChooses);
      reset({
        name: name,
        type: String(type),
        cat_id: String(cat_id),
        is_required: String(is_required),
        chooses: [...mappedChooses]
      });
    }
  }, [selectedQuestion, selectedQuestionId]);

  useEffect(() => {
    if (selectedQuestionId && selectedQuestion) {
      if (watch('type') === '1' || watch('type') === '2') {
        setValue('chooses', []);
      } else {
        setValue(
          'chooses',
          selectedQuestion.question_chooses.map((q) => ({ name: q.name }))
        );
      }
    } else {
      if (watch('type') === '3' || watch('type') === '4' || watch('type') === '5') {
        // setValue('chooses', [{ name: '' }]);
        // appendChooseField({ name: '' });
        reset({
          ...watch()
        });
      } else {
        setValue('chooses', []);
      }
    }
  }, [watch('type'), selectedQuestion, selectedQuestionId]);

  const onSubmit = async (data) => {
    try {
      // console.log('data to be submitted: ', data);

      let res;
      if (selectedQuestion) {
        res = await createQuestion(`/Question/update/${selectedQuestion.id}`, data, user?.token);
      } else {
        res = await createQuestion('/Question/create', data, user?.token);
      }
      // console.log('create service response: ', res);
      if (res.status === 200 && res.data.status === 0) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء السؤال',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.status === 200 && res.data.status === 1) {
        reset({ ...defaultValues });
        setQuestionModalOpened(false);
        setSelectedQuestionId('');
        setSelectedQuestion(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء السؤال المطلوب',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [categories, setCategoires] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await getAllServices(user?.token);
        console.log(res);
        if (res.status === 200 && res.data.data?.length) {
          console.log(res);
          setCategoires(
            res.data.data
              .filter((d) => d.is_question === 1)
              .map((d) => ({ title: d?.name_ar, value: d?.key }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategories();
  }, []);

  console.log('cats: ', categories);
  return (
    <Form
      // initialValues={selectedQuestion && selectedQuestionId ? { ...watch() } : defaultValues}
      // initialValues={{
      //   cat_id: '2'
      // }}
      fields={[
        {
          name: 'name',
          value: watch('name')
        },
        // {
        //   name: 'cat_id',
        //   value: watch('cat_id')
        // },
        {
          name: 'type',
          value: watch('type')
        },
        {
          name: 'is_required',
          value: watch('is_required')
        },
        {
          name: 'chooses',
          value: watch('chooses')
        }
      ]}
      // fields={[
      //   {
      //     name: 'is_required',
      //     value: watch('is_required')
      //   }
      // ]}
      className="add-service-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <AntdTextField
          name="name"
          type="text"
          label="اكتب الســـؤال المطلوب"
          placeholder="الســـؤال..."
          errorMsg={errors?.name?.message}
          validateStatus={errors?.name ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />

        <AntdSelectOption
          name="cat_id"
          placeholder="من فضلك اختار الخدمة الخاص بالسؤال..."
          errorMsg={errors?.cat_id?.message}
          validateStatus={errors?.cat_id ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={categories?.length ? categories : []}
          label={
            <p className="select-option-label">
              <EditOutlined />
              الخدمة الخاصة بالسـؤال
            </p>
          }
          formClassName="add-service-form"
        />

        <div className="answer-type-wrap">
          <AntdRadioGroup
            radios={[
              { title: 'الاجابة على السؤال اجبارية', value: '1' },
              { title: 'الاجابة على السؤال اختيارية', value: '2' }
            ]}
            name="is_required"
            defaultValue={watch('is_required')}
            label={
              <p className="select-option-label">
                <SelectOutlined />
                اختــار نوع الاجابة
              </p>
            }
            validateStatus={errors?.is_required ? 'error' : ''}
            errorMsg={errors?.is_required?.message}
            control={control}
            isRadioButton
          />
        </div>

        <div className={`question-types-wrap ${errors?.type?.message ? 'error-wrap' : ''}`}>
          <p className="main-wrap-title">
            <SelectOutlined />
            اختــار نوع السؤال
          </p>
          <div className="labels-wrap">
            <label className={watch('type') === '1' ? 'selected' : ''}>
              <input hidden type="radio" name="type" {...register('type')} value="1" />
              <Card title="سـؤال كتابى قصير">
                <img src={textFieldImg} alt="text field" />
              </Card>
            </label>
            <label className={watch('type') === '6' ? 'selected' : ''}>
              <input hidden type="radio" name="type" {...register('type')} value="6" />
              <Card title="سـؤال كتابى طويل">
                <img src={textareaImg} alt="textarea" />
              </Card>
            </label>
            <label className={watch('type') === '2' ? 'selected' : ''}>
              <input hidden type="radio" name="type" {...register('type')} value="2" />
              <Card title="رفع ملف">
                <img src={fileUploadImg} alt="file upload" />
              </Card>
            </label>
            <label className={watch('type') === '3' ? 'selected' : ''}>
              <input hidden type="radio" name="type" {...register('type')} value="3" />
              <Card title="اختيار من متعدد">
                <img src={selectOptionImg} alt="select option" />
              </Card>
            </label>
            <label className={watch('type') === '4' ? 'selected' : ''}>
              <input hidden type="radio" name="type" {...register('type')} value="4" />
              <Card title="اختيار من متعدد">
                <img src={radioBtnsImg} alt="radio buttons" />
              </Card>
            </label>
            <label className={watch('type') === '5' ? 'selected' : ''}>
              <input hidden type="radio" name="type" {...register('type')} value="5" />
              <Card title="عدة اختيارات">
                <img src={checkboxsImg} alt="check boxs" />
              </Card>
            </label>
          </div>

          {errors?.type?.message && <p className="error-p">{errors.type.message}</p>}
        </div>

        {(watch('type') === '3' || watch('type') === '4' || watch('type') === '5') && (
          <div
            className={`dynamic-answers-wrap ${
              errors?.chooses?.message ? 'error-answers-wrap' : ''
            }`}>
            <div>
              <p className="wrap-title">الاجابات المقترحة على السؤال</p>
              <ul className="dynamic-inputs-ul">
                {
                  // watch('chooses') &&
                  //   choosesFieldes?.length &&
                  choosesFieldes.map((item, index) => (
                    <li className="field-delete-li" key={item.id}>
                      <AntdTextField
                        name={`chooses.${index}.name`}
                        type="text"
                        placeholder="الاجابة المقترحة على السؤال..."
                        // label="الاســــم"
                        errorMsg={errors.chooses && errors?.chooses[index]?.name?.message}
                        validateStatus={
                          errors.chooses && errors?.chooses[index]?.name ? 'error' : ''
                        }
                        prefix={<EditOutlined />}
                        defaultValue={item?.name ? item.name : ''} // make sure to set up defaultValue
                        control={control}
                      />

                      {choosesFieldes.length > 1 && (
                        <Tooltip title="حذف الاجابة">
                          <Button
                            size="large"
                            type="dashed"
                            shape="circle"
                            icon={<DeleteTwoTone />}
                            onClick={() => removeChooseField(index)}
                          />
                        </Tooltip>
                      )}
                    </li>
                  ))
                }
              </ul>
            </div>

            <Tooltip title="اضافة اجابة">
              <Button
                className="add-asnswer-btn"
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() => appendChooseField({ name: '' })}
              />
            </Tooltip>
            {errors?.chooses?.message && watch('chooses').length <= 1 && (
              <p className="error-p">{errors.chooses.message}</p>
            )}
          </div>
        )}

        <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
          حفظ السؤال
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(watch(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddQuestionForm;
