import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
// import { CategoryPage } from '../../pages/category/CategoryPage';
import ServicesPage from '../../pages/services-page/ServicesPage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import ProjectsPage from '../../pages/projects/ProjectsPage';
import routerLinks from './routerLinks';
import QuestionsPage from '../../pages/questions-page/QuestionsPage';
import { ServicesProvider } from '../../contexts/services-context/ServicesProvider';
import { QuestionsProvider } from '../../contexts/questions-context/QuestionsProvider';
import { AnswersProvider } from '../../contexts/answers-context/AnswersProvider';
import AnswersPage from '../../pages/answers-page/AnswersPage';
import ProfilePage from '../../pages/profile-page/ProfilePage';
import ContactUsPage from '../../pages/contactus-page/ContactUsPage';
import AboutUsPage from '../../pages/about-us/AboutUsPage';
import { AboutProvider } from '../../contexts/aboutus-context/AboutUsProvider';
import NewsLetterPage from '../../pages/news-letter-page/NewsLetterPage';
import { EmailsProvider } from '../../contexts/emails-context/EmailsProvider';
// import { FatorahProvider } from '../../contexts/fatorah-context/FatorahProvider';
import FatorahPage from '../../pages/fatorah-page/FatorahPage';
const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

const Routes = () => {
  return (
    <Switch>
      <Route exact path={routerLinks.homePage} component={HomePage} />

      {/* <Route exact path={routerLinks.categoryPage} component={CategoryPage} /> */}

      <Route exact path={routerLinks.ProfilePage} component={ProfilePage} />
      <Route exact path={routerLinks.servicesPage}>
        <ServicesProvider>
          <ServicesPage />
        </ServicesProvider>
      </Route>
      <Route exact path={routerLinks.projectsPage} component={ProjectsPage} />
      <Route exact path={routerLinks.ContactUsPage} component={ContactUsPage} />

      <Route exact path={routerLinks.aboutUsPage}>
        <AboutProvider>
          <AboutUsPage />
        </AboutProvider>
      </Route>

      <Route exact path={routerLinks.fatorahRequestPage}>
        {/* <FatorahProvider> */}
        <FatorahPage />
        {/* </FatorahProvider> */}
      </Route>

      <Route exact path={routerLinks.questionsPage}>
        <QuestionsProvider>
          <QuestionsPage />
        </QuestionsProvider>
      </Route>
      <Route exact path={routerLinks.answersPage}>
        <AnswersProvider>
          <AnswersPage />
        </AnswersProvider>
      </Route>
      <Route exact path={routerLinks.newsLetterPage}>
        <EmailsProvider>
          <NewsLetterPage />
        </EmailsProvider>
      </Route>
      {/* not found page */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
