import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import React, { useContext, useState } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import * as yup from 'yup';
import { store } from 'react-notifications-component';

import { Button, Form, Popconfirm, Tooltip, Table } from 'antd';
import { EditOutlined, SendOutlined, DeleteOutlined } from '@ant-design/icons';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import addProjectFeature from '../../apis/project-api/addProjectFeature';
import deleteProjectFeature from '../../apis/project-api/deleteProjectFeature';
import UploadProjectFile from './UploadProjectFile';

const schema = yup.object().shape({
  name_ar: yup.string().required('الأسم بالعربي مطلوب'),
  name_en: yup.string().required('الأسم بالانجليزي مطلوب'),
  icon: yup.mixed().required('من فضلك اختار صورة الخاصيه')
});

const defaultValues = {
  name_ar: '',
  name_en: '',
  icon: []
};

const ProjectFeatures = ({ count, project, setcategory, setCount }) => {
  const columns = () => [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'صورة الخاصيه',
      dataIndex: 'image',
      width: 180,
      render: (_, record) => {
        return (
          <div className="service-img">
            <img src={record.icon} alt={record.name_en} width="100" />
          </div>
        );
      },
      key: 'image'
    },
    {
      title: 'الأسم بالعربيه',
      dataIndex: 'name_ar',
      key: 'name_ar'
    },
    {
      title: 'الأسم بالانجليزيه',
      dataIndex: 'name_en',
      key: 'name_en'
    },
    {
      title: 'الأختيارات',
      key: 'action',
      render: (_, record) => (
        <div className="opation-btn">
          <div className="delete">
            <Popconfirm
              title="هل أنت متأكد من الخاصيه"
              okText="نعم"
              cancelText="لا"
              onConfirm={(key) => deletefeatures(record.key)}>
              <Tooltip title="حذف الخاصيه">
                <Button
                  className="delete-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
              {/* <button>حذف</button> */}
            </Popconfirm>
          </div>
        </div>
      )
    }
  ];
  const deletefeatures = async (id) => {
    try {
      const fetchedData = await deleteProjectFeature(id, user?.token);
      if (!fetchedData) {
        store.addNotification({
          title: 'حدث خطأ اثناء حذف الخاصيه',
          message: fetchedData?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم حذف الخاصيه  المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        // console.log(fetchedData.data);
        console.log(fetchedData);
        setCount(count + 1);
        setcategory(fetchedData.data);
        reset({ ...defaultValues });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const { user } = useContext(UesrContext);
  const {
    control,
    setValue,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const [form] = Form.useForm();
  const [urls, setUrls] = useState([]);

  const onSubmit = async (data) => {
    try {
      const fetchedData = await addProjectFeature(data, project.key, user?.token);
      if (!fetchedData) {
        store.addNotification({
          title: 'حدث خطأ ',
          message: fetchedData?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم اضافه المشروع بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        setCount(count + 1);
        setcategory(fetchedData.data);
        //   setIsModalVisible(false);
        reset({ ...defaultValues });
        setUrls([]);
      }
    } catch (error) {
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        className="add-project-form">
        <UploadProjectFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="icon"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          setUrls={setUrls}
          urls={urls}
          label={
            <>
              <EditOutlined />
              صورة الخاصيه
            </>
          }
          errorMsg={errors?.icon ? errors?.icon?.message : ''}
        />
        <div className="form-field-wrapper">
          <AntdTextField
            name="name_ar"
            type="text"
            placeholder="اســم الخاصيه باللغة العربية..."
            errorMsg={errors?.name_ar?.message}
            validateStatus={errors?.name_ar ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="name_en"
            type="text"
            placeholder="اســم الخاصيه باللغة الانجليزيه..."
            errorMsg={errors?.name_en?.message}
            validateStatus={errors?.name_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافة خاصيه
        </Button>
      </Form>
      <div className="table">
        <Table columns={columns()} dataSource={project.features} scroll={{ x: 900 }} />
      </div>
    </div>
  );
};

export default ProjectFeatures;
