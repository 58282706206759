import axios from 'axios';

const getAllQuestions = async (token) => {
  try {
    const getQuestions = await axios.get(`/Question/all`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return getQuestions;
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryQuestions = async (catId, token) => {
  try {
    const getQuestions = await axios.get(`/Question/all?cat_id=${catId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return getQuestions;
  } catch (error) {
    console.log(error);
  }
};

export default getAllQuestions;
