import axios from 'axios';

const addAboutUs = async (url, values, token) => {
  try {
    const { name_ar, name_en, desc_ar, desc_en, image } = values;
    const pickedValues = {
      name_ar,
      name_en,
      desc_ar,
      desc_en
      // image: service_imgs ? service_imgs[0] : null
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }

    if (image && image[0]) {
      formData.append('image', image[0]);
    }

    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export default addAboutUs;
