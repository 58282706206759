import axios from 'axios';

const getAllAnswers = async (token) => {
  try {
    const getAnswers = await axios.get(`/Answer/all`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return getAnswers;
  } catch (error) {
    console.log(error);
  }
};

export default getAllAnswers;
