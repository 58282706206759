import axios from 'axios';

const deleteProjectFeature = async (id, token) => {
    try {
        const formData = new FormData();
        formData.append('feature_id', id);
        const response = await axios.post('/ProjectDetails/deleteFeature', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        });

        // console.log('request project response: ', response);
        if (response.status === 200 && response.data.status === 1) {
            return response.data;
        }
    } catch (e) {
        console.log(e);
    }
};

export default deleteProjectFeature;
