import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Link as RouterLink } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';
import deleteServiceApi from '../../apis/services/deleteService';
import { store } from 'react-notifications-component';

const servicesColumns = (
  token,
  setFetchCount,
  setLoadingServices,
  setServiceModalOpened,
  setSelectedServId
) => {
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingServices(true);
      const res = await deleteServiceApi(key, token);
      if (res.status === 200 && res.data.status === 1) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingServices(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الخدمة بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingServices(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingServices(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const handleEditService = (key) => {
    setServiceModalOpened(true);
    setSelectedServId(key);
    // console.log(key);
  };

  return [
    {
      title: '#',
      dataIndex: 'index',
      width: 62
    },
    {
      title: 'الاســم باللغة العربية',
      dataIndex: 'name_ar',
      width: 192
    },
    {
      title: 'الاســـم باللغة الانجليزية',
      dataIndex: 'name_en',
      width: 192
    },
    // {
    //   title: 'تفاصيل الخدمة باللغة العربية',
    //   dataIndex: 'desc_ar'
    // },
    // {
    //   title: 'تفاصيل الخدمة باللغة الانجليزية',
    //   dataIndex: 'desc_en'
    // },
    {
      title: 'صورة الخدمة',
      dataIndex: 'image',
      width: 180,
      render: (_, record) => {
        return (
          <div className="service-img">
            <img src={record.image} alt={record.name_en} />
          </div>
        );
      }
    },
    {
      title: 'حالة الخدمة',
      dataIndex: 'status',
      width: 82,
      render: (_, record) => {
        if (record.status === 1) {
          return 'نشـط';
        } else {
          return 'غير نشـط';
        }
      }
    },
    {
      title: 'مشاريع الخدمة',
      dataIndex: 'projects',
      width: 92,
      render: (_, record) => {
        if (record.type === 2) {
          return (
            <RouterLink
              className="projects-link"
              to={{
                pathname: routerLinks.projectsPage,
                state: { serviceId: record.key }
              }}>
              {/* المشاريع */}
              <Tooltip title="مشاريع الخدمة">
                <Button
                  className="projects-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<LinkOutlined />}
                />
              </Tooltip>
            </RouterLink>
          );
        }
        return 'لا توجد مشاريع';
      }
    },
    {
      title: 'تعديل الخدمة',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل الخدمة">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditService(record.key)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف الخدمة',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف الخدمة؟</p>
                <p>حذف الخدمة سيؤدى الى حذف جميع المشاريع والاسئلة الخاصة بالخدمة</p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record.key)}>
            <Tooltip title="حذف الخدمة؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default servicesColumns;
