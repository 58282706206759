/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';

import { Select } from 'antd';
import getCategories from '../../apis/category-apis/getCategories';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';

const QuestionsCategoryFilter = () => {
  const { user } = useContext(UesrContext);
  const { Option } = Select;

  const { setSelectedFilter } = useContext(QuestionsContext);

  const [categories, setCategoires] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategories(user?.token);
        if (data?.length) {
          setCategoires(
            data
              .filter((d) => d.is_question === 1)
              .map((d) => ({ title: d?.name_ar, value: d?.key }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategories();
  }, []);

  function handleChange(value) {
    setSelectedFilter(value);
    // const getFilteredQuestions = async () => {
    //   try {
    //     setLoadingQuestions(true);
    //     let res;
    //     if (value === 'all') {
    //       res = await getAllQuestions(user?.token);
    //     } else {
    //       res = await getCategoryQuestions(value, user?.token);
    //     }
    //     if (res.status === 200 && res?.data?.data) {
    //       setAllQuestions(res.data.data);
    //       setLoadingQuestions(false);
    //     }
    //   } catch (error) {
    //     setLoadingQuestions(false);
    //     console.log(error);
    //   }
    // };

    // getFilteredQuestions();
  }

  return (
    <>
      <Select
        className="questions-filter-select"
        defaultValue="all"
        style={{ width: 162 }}
        size="large"
        onChange={handleChange}>
        <Option value="all">جميع الاسئــلة</Option>
        {categories?.length > 0 &&
          categories.map((op, index) => (
            <Option key={index} value={op.value}>
              {op.title}
            </Option>
          ))}
      </Select>
    </>
  );
};

export default QuestionsCategoryFilter;
