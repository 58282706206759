import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeTwoTone } from '@ant-design/icons';
import deleteProject from '../../apis/project-api/deleteProject';
import { store } from 'react-notifications-component';
const deleteProjectCall = async (data, count, setCount, token) => {
  try {
    const res = await deleteProject(data, token);
    if (res.status === 200 && res.data.status === 1) {
      setCount(count + 1);
      store.addNotification({
        title: 'العملية تمت بنجاح',
        message: 'تم حذف المشروع بنجاح',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    } else {
      store.addNotification({
        title: 'حدث خطأ ',
        message: res?.data?.message || 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  } catch (error) {
    console.log(error);
    store.addNotification({
      title: 'حدث خطأ ',
      message: 'حاول فى وقت لاحق',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 2000,
        showIcon: true,
        onScreen: true
      }
    });
  }
};

const columns = (
  projectCount,
  setProjectCount,
  setIsEditModalVisible,
  setcategory,
  token,
  setIsDetailesModalVisible
) => [
  {
    title: '#',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'صورة المشروع',
    dataIndex: 'image',
    width: 180,
    render: (_, record) => {
      return (
        <div className="service-img">
          <img src={record.image} alt={record.name_en} width="100" />
        </div>
      );
    },
    key: 'image'
  },
  {
    title: 'الأسم بالعربيه',
    dataIndex: 'name_ar',
    key: 'name_ar'
  },
  {
    title: 'الأسم بالانجليزيه',
    dataIndex: 'name_en',
    key: 'name_en'
  },

  {
    title: 'تفاصيل المشروع',
    key: 'action',
    render: (_, record) => (
      <div className="opation-btn">
        <div className="detailes">
          <Tooltip title="تفاصيل المشورع">
            <Button
              icon={<EyeTwoTone />}
              size="large"
              type="dashed"
              shape="circle"
              onClick={() => {
                setIsDetailesModalVisible(true);
                setcategory(record);
              }}></Button>
          </Tooltip>
        </div>
      </div>
    )
  },
  {
    title: 'تعديل المشروع',
    key: 'action',
    render: (_, record) => (
      <div className="opation-btn">
        <div className="edit">
          <Tooltip title="تعديل المشورع">
            <Button
              className="edit-btn"
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setIsEditModalVisible(true);
                setcategory(record);
              }}
            />
          </Tooltip>
        </div>
      </div>
    )
  },
  {
    title: 'حذف المشروع',
    key: 'action',
    render: (_, record) => (
      <div className="opation-btn">
        <div className="delete">
          <Popconfirm
            title="هل أنت متأكد من حذف ذلك المشروع"
            onConfirm={(key) => deleteProjectCall(record.key, projectCount, setProjectCount, token)}
            okText="نعم"
            cancelText="لا">
            <Tooltip title="حذف المشورع">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
            {/* <button>حذف</button> */}
          </Popconfirm>
        </div>
      </div>
    )
  }
];

export default columns;
