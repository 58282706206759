import axios from 'axios';

export const signinApi = async (values, langIsoCode) => {
  const { name, password } = values;
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('password', password);
    const signinRes = await axios.post('/Auth_general/login', formData, {
      headers: {
        lang: langIsoCode
      }
    });
    console.log('signin response: ', signinRes);
    return signinRes;
  } catch (error) {
    console.log(error);
  }
};
