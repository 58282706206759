import React, { useContext } from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import NewsLetterTable from './NewsLetterTable';
import EmailsContext from '../../contexts/emails-context/EmailsProvider';
import SendEmailsMessageForm from './SendEmailsMessageForm';
import './NewsLetterPage.scss';

const NewsLetterPage = () => {
  const { emailsModalOpened, setEmailsModalOpened } = useContext(EmailsContext);

  return (
    <div className="news-letter-page">
      <Button
        onClick={() => setEmailsModalOpened(true)}
        className="new-btn"
        type="primary"
        icon={<PlusOutlined />}>
        <span className="btn-text">رســالة جديدة</span>
      </Button>

      <NewsLetterTable />

      <Modal
        className="add-service-modal"
        width="90%"
        style={{ maxWidth: '900px' }}
        title="إضافة رسالة جديدة"
        visible={emailsModalOpened}
        onOk={() => {
          setEmailsModalOpened(false);
        }}
        onCancel={() => {
          setEmailsModalOpened(false);
        }}
        footer={false}>
        <SendEmailsMessageForm />
      </Modal>
    </div>
  );
};

export default NewsLetterPage;
