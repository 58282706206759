import axios from 'axios';

const deleteFatoorh = async (id, token) => {
  try {
    const response = await axios.post('/Fatoorh/delete/' + id, '', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    // console.log('request project response: ', response);
    if (response.status === 200 && response.data.status === 1) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default deleteFatoorh;
