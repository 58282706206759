import React, { useEffect } from 'react';
import Dropzone from 'react-dropzone';
import './dropZone.styles.scss';

const DropZone = (props) => {
  const {
    name,
    errorMsg,
    register,
    unregister,
    multipleFiles,
    handleFilesDrop,
    filesToUpload,
    formName,
    id
  } = props;

  const acceptFiles =
    'image/jpg, image/png, image/jpeg, image/gif, video/mp4, video/mkv, video/avi';
  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles) => handleFilesDrop(acceptedFiles)}
        // accept="image/*, video/*"
        accept={acceptFiles}
        multiple={multipleFiles}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            // filesToUpload.length === 0 && (
            // )
            <div className={`dropZoneWrapper ${filesToUpload.length > 0 ? 'hideMe' : ''}`}>
              <div className={`${filesToUpload.length ? 'filled' : ''}`} {...getRootProps()}>
                <input name={name} id={id} {...getInputProps()} form={formName} />
                <div className={`dropZone ${isDragActive ? 'dragZone' : ''}`}>
                  <div className="placeholderText">
                    <i className="icon-upload-cloud-4"></i>
                    <p>اسحب الصور وضعها هنا...</p>
                    {/* <p>Drop your files here...</p> */}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Dropzone>

      {errorMsg && <p className="error-p">{errorMsg}</p>}
    </>
  );
};

export default DropZone;
