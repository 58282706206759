/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import getAllServices from '../../apis/services/getAllServices';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import servicesColumns from './servicesTableColumns';
import ServicesContext from '../../contexts/services-context/ServicesProvider';
const ServicesTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingServices,
    setLoadingServices,
    allServices,
    setAllServices,
    setServiceModalOpened,
    setSelectedServId
  } = useContext(ServicesContext);
  const [servicesDataSource, setServicesDataSource] = useState([]);

  useEffect(() => {
    const getServices = async () => {
      try {
        setLoadingServices(true);
        const getServicesRes = await getAllServices(user?.token);
        if (getServicesRes.status === 200 && getServicesRes?.data?.data) {
          setAllServices(getServicesRes.data.data);
          setLoadingServices(false);
        }
      } catch (error) {
        setLoadingServices(false);
        console.log(error);
      }
    };
    getServices();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allServices) {
      const mappedServices = allServices.map((serv, index) => ({
        ...serv,
        index: index + 1
      }));
      if (isMounted) {
        setServicesDataSource(mappedServices);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allServices, allServices.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="services-table"
      dataSource={servicesDataSource}
      columns={servicesColumns(
        user?.token,
        setFetchCount,
        setLoadingServices,
        setServiceModalOpened,
        setSelectedServId
      )}
      loading={loadingServices}
      scroll={{ x: 1200 }}
    />
  );
};

export default ServicesTable;
