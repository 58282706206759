import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingAnswers: false,
  setLoadingAnswers: (load) => {},
  allAnswers: [],
  setAllAnswers: (services) => {},
  answerModalOpened: false,
  setAnswerModalOpened: (v) => {},
  selectedAnswerId: '',
  setSelectedAsnswerId: (id) => {},
  selectedAnswer: null,
  setSelecteAnswer: (servKey) => {}
};

const AnswersContext = createContext(INITIAL_VALUES);

export const AnswersProvider = ({ children }) => {
  const [allAnswers, setAllAnswers] = useState(INITIAL_VALUES.allAnswers);
  const [loadingAnswers, setLoadingAnswers] = useState(INITIAL_VALUES.loadingAnswers);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [answerModalOpened, setAnswerModalOpened] = useState(INITIAL_VALUES.answerModalOpened);
  const [selectedAnswerId, setSelectedAsnswerId] = useState(INITIAL_VALUES.selectedAnswerId);
  const [selectedAnswer, setSelecteAnswer] = useState(INITIAL_VALUES.selectedAnswer);

  return (
    <AnswersContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingAnswers,
        setLoadingAnswers,
        allAnswers,
        setAllAnswers,
        answerModalOpened,
        setAnswerModalOpened,
        selectedAnswerId,
        setSelectedAsnswerId,
        selectedAnswer,
        setSelecteAnswer
      }}>
      {children}
    </AnswersContext.Provider>
  );
};

export default AnswersContext;
