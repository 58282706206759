import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import 'antd/dist/antd.css';
import 'antd/dist/antd.less';
import './scss/index.scss';

import Loading from './common/loading/Loading';
import { UserProvider } from './contexts/user-context/UserProvider';

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <UserProvider>
      <Router>
        <App />
      </Router>
    </UserProvider>
  </Suspense>,
  document.getElementById('root')
);
