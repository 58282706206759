import axios from 'axios';

const updateProfile = async (values, token) => {
  try {
    const { name, phone, email, password, image } = values;
    const pickedValues = {
      name,
      phone,
      email,
      password,
      image: image[0]
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }

    const response = await axios.post('/Auth_private/edit_profile', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    if (response.status === 200 && response.data.status === 1 && response.data.data) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default updateProfile;
