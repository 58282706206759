import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout as AntdLayout, Menu, Breadcrumb, Dropdown, Button } from 'antd';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  HomeOutlined,
  AppstoreAddOutlined,
  CodeOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SolutionOutlined,
  MenuFoldOutlined,
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  LoadingOutlined,
  CustomerServiceOutlined,
  CopyOutlined,
  MailOutlined
} from '@ant-design/icons';
// import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';

import routerLinks from './routerLinks';
import Logo from '../../common/logo/Logo';
import slugify from 'slugify';
import Avatar from 'antd/lib/avatar/avatar';
import UesrContext from '../../contexts/user-context/UserProvider';
import signoutApi from '../../apis/auth/signout';
import './Layout.scss';

const AppLayout = ({ children }) => {
  const { user, removeCurrentUser } = useContext(UesrContext);

  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  // const { Header, Content, Footer, Sider } = AntdLayout;
  const { Header, Content, Sider } = AntdLayout;

  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const toggleCollapsed = () => {
    setCollapsed((prevState) => !prevState);
  };

  const mainLinks = [
    {
      id: 1,
      path: routerLinks.homePage,
      name: 'الرئيســية',
      icon: <HomeOutlined />
    },
    // {
    //   id: 2,
    //   path: routerLinks.categoryPage,
    //   name: 'الاقســــام',
    //   icon: <PieChartOutlined />
    // },
    {
      id: 3,
      path: routerLinks.servicesPage,
      name: 'الخدمــات',
      icon: <AppstoreAddOutlined />
    },
    {
      id: 4,
      path: routerLinks.projectsPage,
      name: 'المشــاريع',
      icon: <CodeOutlined />
    },
    {
      id: 5,
      path: routerLinks.questionsPage,
      name: 'الاسئــــلة',
      icon: <QuestionOutlined />
    },
    {
      id: 6,
      path: routerLinks.answersPage,
      name: 'طلبات المشــاريع',
      icon: <SolutionOutlined />
    },
    {
      id: 7,
      path: routerLinks.aboutUsPage,
      name: 'من نحن',
      icon: <CopyOutlined />
    },
    {
      id: 8,
      path: routerLinks.ContactUsPage,
      name: 'تواصل معنا',
      icon: <CustomerServiceOutlined />
    },
    {
      id: 9,
      path: routerLinks.newsLetterPage,
      name: 'نشرتنا الاخبارية',
      icon: <MailOutlined />
    },
    {
      id: 10,
      path: routerLinks.fatorahRequestPage,
      name: 'طلبات فاتوره',
      icon: <MailOutlined />
    }
  ];

  const renderMainLinks = () => {
    return mainLinks.map((link) => (
      <Menu.Item
        key={link.id}
        icon={link.icon}
        className={slugify(pathname) === slugify(link.path) ? 'ant-menu-item-selected' : ''}>
        <RouterLink to={link.path}>{link.name}</RouterLink>
      </Menu.Item>
    ));
  };

  const [loadingSignout, setLoadingSignout] = useState(false);
  const history = useHistory();
  const handleSignout = async () => {
    try {
      setLoadingSignout(true);
      const res = await signoutApi(user?.token);
      if (res?.status === 200 && res?.data?.status === 1) {
        setLoadingSignout(false);
        removeCurrentUser();
        history.push(routerLinks.homePage);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تسجيل الخروج بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingSignout(false);
        store.addNotification({
          title: 'حدث خطأ اثناء تسجيل الخروج',
          message: 'من فضلك حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingSignout(false);
      store.addNotification({
        title: 'حدث خطأ اثناء تسجيل الخروج',
        message: 'من فضلك حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
      console.log(error);
    }
  };

  return (
    <div className={`app-layout app-${i18n.dir()}`}>
      <AntdLayout style={{ minHeight: '100vh' }}>
        <Sider
          trigger={null}
          theme="light"
          width="252"
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            // console.log(broken);
          }}>
          {/* <div className="logo" /> */}
          <Logo className="logo-link" />
          <Menu className="app-aside-menu" theme="light" mode="inline" selectable={false}>
            {renderMainLinks()}
          </Menu>
        </Sider>
        <AntdLayout className="site-layout app-site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger-layout-btn',
              onClick: toggleCollapsed
            })} */}
            {collapsed ? (
              <MenuUnfoldOutlined className="trigger-layout-btn" onClick={toggleCollapsed} />
            ) : (
              <MenuFoldOutlined className="trigger-layout-btn" onClick={toggleCollapsed} />
            )}

            <div className="avatar-wrapper">
              <Dropdown
                trigger={['click']}
                disabled={loadingSignout}
                overlay={
                  <Menu>
                    <Menu.Item key="1" icon={<UserOutlined />}>
                      <RouterLink to={routerLinks.ProfilePage}>الملــف الشخصى</RouterLink>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleSignout}>
                      تسجيل الخروج
                    </Menu.Item>
                  </Menu>
                }>
                <Button className="profile-menu-btn" type="text">
                  {loadingSignout ? <LoadingOutlined /> : <DownOutlined />}
                  <span className="user-name">{user?.name}</span>
                  <Avatar size={38} icon={<UserOutlined />} src={user?.image} />
                </Button>
              </Dropdown>
            </div>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}></Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              {children}
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>Layout footer</Footer> */}
        </AntdLayout>
      </AntdLayout>
      {/* <ReactNotification className={i18n.dir()} /> */}
    </div>
  );
};

export default AppLayout;
