import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingServices: false,
  setLoadingServices: (load) => {},
  allServices: [],
  setAllServices: (services) => {},
  serviceModalOpened: false,
  setServiceModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedService: null,
  setSelectedService: (servKey) => {}
};

const ServicesContext = createContext(INITIAL_VALUES);

export const ServicesProvider = ({ children }) => {
  const [allServices, setAllServices] = useState(INITIAL_VALUES.allServices);
  const [loadingServices, setLoadingServices] = useState(INITIAL_VALUES.loadingServices);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [serviceModalOpened, setServiceModalOpened] = useState(INITIAL_VALUES.serviceModalOpened);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedService, setSelectedService] = useState(INITIAL_VALUES.selectedService);

  return (
    <ServicesContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingServices,
        setLoadingServices,
        allServices,
        setAllServices,
        serviceModalOpened,
        setServiceModalOpened,
        selectedServId,
        setSelectedServId,
        selectedService,
        setSelectedService
      }}>
      {children}
    </ServicesContext.Provider>
  );
};

export default ServicesContext;
