const routerLinks = {
  ////////////////////////////
  loginPage: '/',
  homePage: '/',
  categoryPage: '/categories',
  servicesPage: '/services',
  projectsPage: '/projects',
  projectPage: '/projects/:projectId',
  questionsPage: '/questions',
  answersPage: '/answers',
  ProfilePage: '/Profile',
  aboutUsPage: '/about-us',
  ContactUsPage: '/ContactUs',
  newsLetterPage: '/news-letter',
  fatorahRequestPage: '/fatorah-request',
  ////////////////////////////////
  notFound: '/not-found'
};

export default routerLinks;
