import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingAboutData: false,
  setLoadingAboutData: (load) => {},
  allAboutData: [],
  setAllAboutData: (v) => {},
  aboutModalOpened: false,
  setAboutModalOpened: (v) => {},
  selectedAboutId: '',
  setSelectedAboutId: (id) => {},
  selectedAbout: null,
  setSelectedAbout: (servKey) => {}
};

const AboutContext = createContext(INITIAL_VALUES);

export const AboutProvider = ({ children }) => {
  const [allAboutData, setAllAboutData] = useState(INITIAL_VALUES.allAboutData);
  const [loadingAboutData, setLoadingAboutData] = useState(INITIAL_VALUES.loadingAboutData);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [aboutModalOpened, setAboutModalOpened] = useState(INITIAL_VALUES.aboutModalOpened);
  const [selectedAboutId, setSelectedAboutId] = useState(INITIAL_VALUES.selectedAboutId);
  const [selectedAbout, setSelectedAbout] = useState(INITIAL_VALUES.selectedAbout);

  return (
    <AboutContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingAboutData,
        setLoadingAboutData,
        allAboutData,
        setAllAboutData,
        aboutModalOpened,
        setAboutModalOpened,
        selectedAboutId,
        setSelectedAboutId,
        selectedAbout,
        setSelectedAbout
      }}>
      {children}
    </AboutContext.Provider>
  );
};

export default AboutContext;
