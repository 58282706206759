import { Button, Popconfirm, Tag, Tooltip, Popover } from 'antd';
import { DeleteOutlined, EditOutlined, EyeTwoTone, ReadOutlined } from '@ant-design/icons';
import { store } from 'react-notifications-component';
import countWords from '../../utils/countWords';
import trimWords from '../../utils/trimWords';
import deleteQuestionApi from '../../apis/questions/deleteQuestion';

const PopOverAnswers = ({ record, title }) => (
  <Popover
    title={title}
    content={
      <ul className="popover-answers-wrap">
        {record.question_chooses.map((c) => (
          <li key={c.id}>{c?.name ? c.name : ''}</li>
        ))}
      </ul>
    }>
    <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
  </Popover>
);

const questionsColumns = (
  token,
  setFetchCount,
  setLoadingQuestions,
  setQuestionModalOpened,
  setSelectedQuestionId
) => {
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingQuestions(true);
      const res = await deleteQuestionApi(key, token);
      if (res.status === 200 && res.data.status === 1) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingQuestions(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف السؤال بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingQuestions(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingQuestions(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const handleEditQuestion = (key) => {
    setQuestionModalOpened(true);
    setSelectedQuestionId(key);
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62,
      key: 'id'
    },
    {
      title: 'الســؤال',
      dataIndex: 'name',
      width: 220,
      key: 'name',
      render: (text) => {
        const wordsCount = countWords(text);
        if (wordsCount > 3) {
          return (
            <Popover title="اقرأ المزيد" content={text}>
              <Button size="large" type="text">
                <p className="has-read-more">
                  <span>{trimWords(text, 3)}</span>
                  <ReadOutlined className="read-more-book" />
                </p>
              </Button>
            </Popover>
          );
        } else return text;
      }
    },
    {
      title: 'الاجــابة',
      dataIndex: 'question_chooses',
      width: 120,
      key: 'question_chooses',
      render: (_, record) => {
        // textfield === 1
        // file upload === 2
        // select option === 3
        // radio buttons === 4
        // checkboxs === 5
        // textfield === 6
        if (record.type === 1) return '------';
        else if (record.type === 2) return '------';
        else if (record.type === 6) return '------';
        else if (record.type === 3) {
          if (record.question_chooses.length > 0) {
            return <PopOverAnswers record={record} title="اجابات السؤال المقترحة" />;
          }
          return '------';
        } else if (record.type === 4) {
          if (record.question_chooses.length > 0) {
            return <PopOverAnswers record={record} title="اجابات السؤال المقترحة" />;
          }
          return '------';
        } else if (record.type === 5) {
          if (record.question_chooses.length > 0) {
            return <PopOverAnswers record={record} title="اجابات السؤال المقترحة" />;
          }
          return '------';
        }
      }
    },
    {
      title: 'نوع السؤال',
      dataIndex: 'type',
      width: 122,
      key: 'type',
      render: (_, record) => {
        if (record.type === 1) {
          return <Tag color="blue">سؤال كتابى قصير</Tag>;
        } else if (record.type === 6) {
          return <Tag color="processing">سؤال كتابى طويل</Tag>;
        } else if (record.type === 2) {
          return <Tag color="cyan">رفع ملف</Tag>;
        } else if (record.type === 3) {
          return <Tag color="purple">اختيار احادى</Tag>;
        } else if (record.type === 4) {
          return <Tag color="purple">اختيار احادى</Tag>;
        } else if (record.type === 5) {
          return <Tag color="yellow">عدة اختيارات</Tag>;
        }
      }
    },
    {
      title: 'هل الاجابة ملطلوبة',
      dataIndex: 'is_required',
      width: 122,
      key: 'is_required',
      render: (_, record) => {
        if (record.is_required === 1) {
          return <Tag color={'orange'}>السؤال اجبارى</Tag>;
        }
        return <Tag color={'success'}>السؤال غير اجبارى</Tag>;
      }
    },
    {
      title: 'تعديل السؤال',
      dataIndex: 'edit',
      width: 92,
      key: 'edit',
      render: (_, record) => {
        return (
          <Tooltip title="تعديل السؤال">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditQuestion(record.key)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف السؤال',
      dataIndex: 'delete',
      width: 92,
      key: 'delete',
      render: (_, record) => {
        return (
          <Popconfirm
            title="هل أنت متأكد من حذف السؤال؟"
            onConfirm={(key) => handleDeleteRequest(record.key)}
            okText="نعم"
            cancelText="لا">
            <Tooltip title="حذف السؤال؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default questionsColumns;
