import axios from 'axios';

const createService = async (url, values, token) => {
  try {
    const { name_ar, name_en, desc_ar, desc_en, service_imgs, status, type, is_question } = values;
    const pickedValues = {
      name_ar,
      name_en,
      desc_ar,
      desc_en,
      status,
      type,
      is_question
      // image: service_imgs ? service_imgs[0] : null
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }

    if (service_imgs && service_imgs[0]) {
      formData.append('image', service_imgs[0]);
    }

    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default createService;
