import React from 'react';
import ProfileForm from './ProfileForm';
import './ProfilePage.scss';

function ProfilePage() {
  return (
    <div className="profile-page">
      <ProfileForm />
    </div>
  );
}

export default ProfilePage;
