import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingQuestions: false,
  setLoadingQuestions: (load) => {},
  allQuestions: [],
  setAllQuestions: (services) => {},
  questionModalOpened: false,
  setQuestionModalOpened: (v) => {},
  selectedQuestionId: '',
  setSelectedQuestionId: (id) => {},
  selectedQuestion: null,
  setSelectedQuestion: (servKey) => {},
  selectedFilter: '',
  setSelectedFilter: (v) => {}
};

const QuestionsContext = createContext(INITIAL_VALUES);

export const QuestionsProvider = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState(INITIAL_VALUES.selectedFilter);
  const [allQuestions, setAllQuestions] = useState(INITIAL_VALUES.allQuestions);
  const [loadingQuestions, setLoadingQuestions] = useState(INITIAL_VALUES.loadingQuestions);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [questionModalOpened, setQuestionModalOpened] = useState(
    INITIAL_VALUES.questionModalOpened
  );
  const [selectedQuestionId, setSelectedQuestionId] = useState(INITIAL_VALUES.selectedQuestionId);
  const [selectedQuestion, setSelectedQuestion] = useState(INITIAL_VALUES.selectedQuestion);

  return (
    <QuestionsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingQuestions,
        setLoadingQuestions,
        selectedFilter,
        setSelectedFilter,
        allQuestions,
        setAllQuestions,
        questionModalOpened,
        setQuestionModalOpened,
        selectedQuestionId,
        setSelectedQuestionId,
        selectedQuestion,
        setSelectedQuestion
      }}>
      {children}
    </QuestionsContext.Provider>
  );
};

export default QuestionsContext;
