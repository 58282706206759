/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import getAllEmails from '../../apis/emails-apis/getAllEmail';
import UesrContext from '../../contexts/user-context/UserProvider';
import './NewsLetterTable.scss';
import newsLetterTableColumns from './NewsLetterTableColumns';
import EmailsContext from '../../contexts/emails-context/EmailsProvider';

const NewsLetterTable = () => {
  const { user } = useContext(UesrContext);
  const { fetchCount, setFetchCount, loadingEmails, setLoadingEmails, allEmails, setAllEmails } =
    useContext(EmailsContext);

  const [emailsDataSource, setEmailsDataSource] = useState([]);

  useEffect(() => {
    const fetchAllEmails = async () => {
      try {
        setLoadingEmails(true);
        const res = await getAllEmails(user?.token);
        if (res.status === 200 && res.data.data?.length) {
          setAllEmails(res.data.data);
          setLoadingEmails(false);
        }
      } catch (error) {
        setLoadingEmails(false);
        console.log(error);
      }
    };
    fetchAllEmails();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;
    if (allEmails) {
      const mappedEmails = allEmails.map((email, index) => ({
        ...email,
        key: email.id,
        index: index + 1
      }));
      if (isMounted) {
        setEmailsDataSource(mappedEmails);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allEmails, allEmails.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="news-letter-table"
      dataSource={emailsDataSource}
      columns={newsLetterTableColumns(user?.token, setFetchCount, setLoadingEmails)}
      loading={loadingEmails}
      scroll={{ x: 700 }}
    />
  );
};

export default NewsLetterTable;
