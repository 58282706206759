import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingEmails: false,
  setLoadingEmails: (load) => {},
  allEmails: [],
  setAllEmails: (services) => {},
  emailsModalOpened: false,
  setEmailsModalOpened: (v) => {}
};

const EmailsContext = createContext(INITIAL_VALUES);

export const EmailsProvider = ({ children }) => {
  const [allEmails, setAllEmails] = useState(INITIAL_VALUES.allEmails);
  const [loadingEmails, setLoadingEmails] = useState(INITIAL_VALUES.loadingEmails);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [emailsModalOpened, setEmailsModalOpened] = useState(INITIAL_VALUES.fetchCount);

  return (
    <EmailsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingEmails,
        setLoadingEmails,
        allEmails,
        setAllEmails,
        emailsModalOpened,
        setEmailsModalOpened
      }}>
      {children}
    </EmailsContext.Provider>
  );
};

export default EmailsContext;
