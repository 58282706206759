import axios from 'axios';

const createQuestion = async (url, values, token) => {
  try {
    const { name, type, cat_id, is_required, chooses } = values;
    const pickedValues = { name, type, cat_id, is_required };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }
    if (chooses.length > 0) {
      for (let i of chooses) {
        formData.append('chooses[]', i.name);
      }
    }

    const createQuestionsRes = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createQuestionsRes;
  } catch (error) {
    console.log(error);
  }
};

export default createQuestion;
