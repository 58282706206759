import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import { DeleteOutlined, EyeTwoTone } from '@ant-design/icons';
import { store } from 'react-notifications-component';
import deleteFatoorh from '../../apis/fatorah-apis/deleteFatoorh';

const PopOverAnswers = ({ content, title }) => (
  <Popover title={title} content={content}>
    <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
  </Popover>
);

const deleteFatorahCall = async (data, count, setCount, token) => {
  try {
    const res = await deleteFatoorh(data, token);
    // console.log(res);
    if (res.status === 1) {
      setCount(count + 1);
      store.addNotification({
        title: 'العملية تمت بنجاح',
        message: 'تم حذف  بنجاح',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    } else {
      store.addNotification({
        title: 'حدث خطأ ',
        message: res?.data?.message || 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  } catch (error) {
    console.log(error);
    store.addNotification({
      title: 'حدث خطأ ',
      message: 'حاول فى وقت لاحق',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 2000,
        showIcon: true,
        onScreen: true
      }
    });
  }
};

const fatorahTableColumns = (fatorahCount, setFatorahCount, token) => [
  {
    title: '#',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'الأسم ',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'رقم الهاتف',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'البريد الالكتروني',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'النشاط',
    dataIndex: 'activity',
    key: 'activity'
  },
  {
    title: 'ملاحظات ',
    dataIndex: 'note',
    width: 82,
    render: (v) => {
      return <PopOverAnswers content={v} title="تفاصيل الطلب" />;
    }
  },
  {
    title: 'حذف الطلب',
    key: 'action',
    render: (_, record) => (
      <div className="opation-btn">
        <div className="delete">
          <Popconfirm
            title="هل أنت متأكد من حذف ذلك الطلب"
            onConfirm={(key) => deleteFatorahCall(record.key, fatorahCount, setFatorahCount, token)}
            okText="نعم"
            cancelText="لا">
            <Tooltip title="حذف الطلب">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
            {/* <button>حذف</button> */}
          </Popconfirm>
        </div>
      </div>
    )
  }
];

export default fatorahTableColumns;
