/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import * as yup from 'yup';
import { store } from 'react-notifications-component';
import { Button, Form, Popconfirm, Tooltip } from 'antd';
import { SendOutlined, DeleteOutlined } from '@ant-design/icons';
import addProjectScreen from '../../apis/project-api/addProjectScreen';
import deleteProjectImage from '../../apis/project-api/deleteProjectImage';
import DropZone from '../../common/dorp-zone/dropZoneComponent';
import DropZonePreview from '../../common/dorp-zone/dropZonePreviewComponent';

import './ProjectImage.scss';

const schema = yup.object().shape({
  image_project: yup.mixed().required('من فضلك اختار صورة المشروع')
});

const defaultValues = {
  image_project: null
};

const ProjectImage = (count, project, setcategory, setCount) => {
  const renderScreens = () => {
    return count.project.screens.map(({ id, image }) => (
      <div className="img-delete-wrap" key={id}>
        <Popconfirm
          title="هل أنت متأكد من حذف تلك الصوره"
          onConfirm={(key) => deleteImage(id)}
          okText="نعم"
          cancelText="لا">
          <Tooltip title="حذف الصوره">
            <Button
              className="delete-img-btn"
              size="large"
              type="dashed"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
          {/* <button>حذف</button> */}
        </Popconfirm>

        <img src={image} alt={id} />
      </div>
    ));
  };

  useEffect(() => {
    reset({ ...defaultValues });
  }, []);

  const { user } = useContext(UesrContext);
  const {
    setValue,
    handleSubmit,
    register,

    unregister,
    reset,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const deleteImage = async (id) => {
    try {
      const fetchedData = await deleteProjectImage(id, user?.token);
      if (!fetchedData) {
        store.addNotification({
          title: 'حدث خطأ اثناء حذف الصوره',
          message: fetchedData?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم حذف الصوره  المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        // console.log(fetchedData.data);
        // console.log(fetchedData);
        count.setCount(count.count + 1);
        count.setcategory(fetchedData.data);
        reset({ ...defaultValues });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // deleteProjectImage
  const onSubmit = async (data) => {
    try {
      const fetchedData = await addProjectScreen(data, count.project.key, user?.token);
      if (!fetchedData) {
        store.addNotification({
          title: 'حدث خطأ اثناء اضافه الصوره',
          message: fetchedData?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم اضافه الصوره  المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        // console.log(fetchedData.data);

        count.setCount(count.count + 1);
        count.setcategory(fetchedData.data);
        reset({ ...defaultValues });
        setFilesToUpload([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  const [filesToUpload, setFilesToUpload] = useState([]);
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      for (let f of filesToUpload) {
        if (i.name === f.name) {
          duplicates.push(i);
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }

    // const files = async () =>  acceptedFiles.map((file) => {
    //   const reader = new FileReader();
    //   reader.addEventListener('load', () => {
    //     Object.assign(file, {
    //       preview: reader.result
    //     });
    //   });
    //   reader.readAsDataURL(file);

    //   return file;
    // });

    const files = acceptedFiles.map(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      console.log('file: ', file);
      setFilesToUpload((prevState) => [...prevState, file]);
    });
    // const files = acceptedFiles.map((file) =>
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file)
    //   })
    // );
    console.log('files: ', await files);

    // setFilesToUpload((prevState) => [...prevState, ...files]);
  };

  const clearFileFromUpload = (file) => {
    setFilesToUpload((prevState) => prevState.filter((asset) => asset.name !== file.name));
  };

  useEffect(() => {
    register('image_project');
    return () => {
      unregister('image_project');
    };
  }, [register, unregister, 'image_project']);

  useEffect(() => {
    setValue('image_project', filesToUpload);
  }, [filesToUpload, filesToUpload?.length]);

  // console.log('files to upload :', filesToUpload);
  // console.log('watch: ', watch('image_project'));
  // console.log('getvalues: ', getValues('image_project'));

  return (
    <div className="image-project">
      <div className="add-img">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          className="add-project-form add-project-images-form">
          <DropZone
            filesToUpload={filesToUpload}
            handleFilesDrop={handleFilesDrop}
            formName="image_project"
            name="image_project"
            register={register}
            unregister={unregister}
            id="newAvatarId"
            multipleFiles={true}
          />
          <DropZonePreview
            filesToUpload={filesToUpload}
            clearFileFromUpload={clearFileFromUpload}
          />

          {/* <UploadProjectFile
            accept="image/png, image/jpg, image/jpeg, image/gif"
            maxFiles={1}
            name="image_project"
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            multiple={false}
            setUrls={setUrls}
            urls={urls}
            label={
              <>
                <EditOutlined />
                أضافه صوره للمشروع
              </>
            }
            errorMsg={errors?.image_project ? errors?.image_project?.message : ''}
          /> */}
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            icon={<SendOutlined />}
            loading={isSubmitting}>
            اضافة صوره
          </Button>
        </Form>
      </div>
      {renderScreens()}
    </div>
  );
};

export default ProjectImage;
