import axios from 'axios';

const getProjects = async (token) => {
  try {
    const response = await axios.get(`/Project/all`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    if (response.status === 200 && response.data.status === 1 && response.data.data) {
      return response.data.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default getProjects;
