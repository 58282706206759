import React from 'react';
import LoginForm from './LoginForm';
import signinImg from '../../assets/imgs/signin/signin.svg';
import './LoginPage.scss';

const LoginPage = () => {
  return (
    <div className="login-page">
      <div className="custom-container">
        <div className="page-wrapper">
          <div className="form-top-level-wrapper">
            <LoginForm />
          </div>
          <div className="img-wrap">
            <img src={signinImg} alt="signin" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
