/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import ServicesContext from '../../contexts/services-context/ServicesProvider';

const UploadAboutFile = (props) => {
  const { name, label, accept, errorMsg, register, unregister, setValue, multiple } = props;
  const { selectedService, selectedServId } = useContext(ServicesContext);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    // onDrop,
    accept: accept,
    multiple
  });

  console.log('accepted files: ', acceptedFiles);

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      setValue(name, acceptedFiles, { shouldValidate: true });

      // read file
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        props.setUrls([reader.result]);
      });
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, [acceptedFiles, name]);
  console.log('accepted files: ', acceptedFiles);

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  useEffect(() => {
    if (selectedService && selectedService.image) {
      props.setUrls([selectedService.image]);
    } else {
      console.log('not selected');
      props.setUrls([]);
    }
  }, [selectedService, selectedServId]);

  return (
    <div className="upload-files-wrapper">
      <label className="file-input-label" htmlFor={name}>
        {label}
      </label>
      <div className="dropzone-itself" {...getRootProps()}>
        <input
          name={name}
          lbale={label}
          accept={accept}
          className="file-input-itself"
          id={name}
          {...getInputProps()}
        />
        <div className={'dashed-border' + (isDragActive ? 'gray-500-bg' : 'gray-200-bg')}>
          {!props.urls?.length && (
            <p className="text-center my-2">اسحب صورة الخدمة وضعها هنا ...</p>
          )}
          {props.urls?.length > 0 && (
            <div className="imgs-preview-wrapper">
              {props.urls.map((url, index) => {
                return (
                  <div className="img-preview" key={index}>
                    <img src={url} alt="preview" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {errorMsg && <p className="error-p">{errorMsg}</p>}
    </div>
  );
};

export default UploadAboutFile;
