import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
const DropZonePreview = ({ post, filesToUpload, clearFileFromUpload }) => {
  console.log('props files to upload :', filesToUpload);

  return (
    <>
      {filesToUpload.length > 0 && (
        <div className="previewFiles">
          {filesToUpload.map((file) =>
            file?.type?.match('video*') ? (
              <div key={file?.path} className="videoPreview">
                <video src={file?.preview}></video>
                <i className="icon-cancel" onClick={() => clearFileFromUpload(file, post)}></i>
              </div>
            ) : (
              <div key={file?.path} className="imgPreview">
                <img src={file?.preview} alt="thumbnail img" />
                <Button
                  className="remove-file-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  onClick={() => clearFileFromUpload(file, post)}
                  icon={<DeleteOutlined />}
                />
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};

export default DropZonePreview;
