import axios from 'axios';

const deleteAboutApi = async (aboutId, token) => {
  try {
    const response = await axios.post(`/About/delete/${aboutId}`, null, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    // console.log('delete service response: ', response);
    if (response.status === 200 && response.data.status === 1) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export default deleteAboutApi;
