import { Tabs } from 'antd';
import React from 'react';
import AddProjectTechnology from './AddProjectTechnology';
import ProjectFeatures from './ProjectFeatures';
import ProjectImage from './ProjectImage';

const ProjectDetailes = ({ project, setcategory, setCount, count }) => {
  // console.log(setcategory);
  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="صور المشروع" key="1">
        <ProjectImage
          project={project}
          setcategory={setcategory}
          setCount={setCount}
          count={count}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="تقنيات" key="2">
        <AddProjectTechnology
          count={count}
          project={project}
          setcategory={setcategory}
          setCount={setCount}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="الخصائص" key="3">
        <ProjectFeatures
          count={count}
          project={project}
          setcategory={setcategory}
          setCount={setCount}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ProjectDetailes;
