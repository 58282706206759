import React, { useState, useEffect, useContext } from 'react';
import PopUp from '../../common/popup/PopUp';
import { Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ProjectsTable from '../../components/project-page-components/ProjectsTable';
import AddProjectForm from '../../components/project-page-components/AddProjectForm';
import EditProjectForm from '../../components/project-page-components/EditProjectForm';
import ProjectDetailes from '../../components/project-page-components/ProjectDetailes';
import getAllServices from '../../apis/services/getAllServices';
import UesrContext from '../../contexts/user-context/UserProvider';
import { useHistory, useLocation } from 'react-router';

import './ProjectsPage.scss';

const ProjectsPage = () => {
  const [count, setCount] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const [isDetailesModalVisible, setIsDetailesModalVisible] = useState(false);

  const [category, setcategory] = useState({});

  const [service, setService] = useState([]);
  const [serviceId, setServiceId] = useState(0);
  const { user } = useContext(UesrContext);

  const showModal = () => {
    setIsModalVisible(true);
  };

  let location = useLocation();
  console.log('ssss', location);

  const { Option } = Select;
  const history = useHistory();
  function handleChange(value) {
    history.replace({
      ...location,
      state: undefined
    });
    setServiceId(value);
    setCount(count + 1);
    console.log(value);
  }

  useEffect(() => {
    const getServices = async () => {
      try {
        const res = await getAllServices(user?.token);
        if (res?.status && res?.data?.status === 1 && res?.data?.data) {
          const filteredGalleryServies = res.data.data.filter((d) => d.type === 2);
          setService(filteredGalleryServies);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getServices();
  }, [user?.token]);

  return (
    <div className="project-page">
      <Button onClick={showModal} className="add-btn" type="primary" icon={<PlusOutlined />}>
        <span className="btn-text">اضافة مشروع</span>
      </Button>

      <Select
        defaultValue={location?.state?.serviceId ? location.state.serviceId : 'all'}
        className="questions-filter-select"
        style={{ width: 162 }}
        size="large"
        onChange={handleChange}>
        <Option value="all">جميع الخدمات</Option>
        {service?.length > 0 &&
          service.map((op, index) => (
            <Option key={index} value={op.key}>
              {op.name_ar}
            </Option>
          ))}
      </Select>

      {isModalVisible && (
        <PopUp
          children={
            <AddProjectForm
              count={count}
              setCount={setCount}
              setIsModalVisible={setIsModalVisible}
            />
          }
          title="أضافه مشروع"
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      )}

      {isEditModalVisible && (
        <PopUp
          children={
            <EditProjectForm
              count={count}
              setCount={setCount}
              setIsModalVisible={setIsEditModalVisible}
              Project={category}
            />
          }
          title="تعديل مشروع"
          setIsModalVisible={setIsEditModalVisible}
          isModalVisible={isEditModalVisible}
        />
      )}

      {isDetailesModalVisible && (
        <PopUp
          title="تفاصيل المشروع"
          setIsModalVisible={setIsDetailesModalVisible}
          isModalVisible={isDetailesModalVisible}>
          <ProjectDetailes
            count={count}
            setCount={setCount}
            setIsDetailesModalVisible={setIsDetailesModalVisible}
            project={category}
            setcategory={setcategory}
          />
        </PopUp>
      )}

      <ProjectsTable
        serviceId={serviceId}
        projectCount={count}
        setProjectCount={setCount}
        setIsEditModalVisible={setIsEditModalVisible}
        setIsDetailesModalVisible={setIsDetailesModalVisible}
        isEditModalVisible={isEditModalVisible}
        setcategory={setcategory}
      />
    </div>
  );
};

export default ProjectsPage;
