/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import questionsColumns from './questionsTableColumns';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';
import getAllQuestions, { getCategoryQuestions } from '../../apis/questions/getAllQuestions';
const QuestionsTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingQuestions,
    setLoadingQuestions,
    selectedFilter,
    allQuestions,
    setAllQuestions,
    setQuestionModalOpened,
    setSelectedQuestionId
  } = useContext(QuestionsContext);
  const [questionsDataSource, setQuestionsDataSource] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getQuestions = async () => {
      try {
        setLoadingQuestions(true);
        let getQuestionsRes;
        if (selectedFilter) {
          if (selectedFilter === 'all') getQuestionsRes = await getAllQuestions(user?.token);
          else getQuestionsRes = await getCategoryQuestions(selectedFilter, user?.token);
        } else {
          getQuestionsRes = await getAllQuestions(user?.token);
        }
        if (getQuestionsRes.status === 200 && getQuestionsRes?.data?.data) {
          if (isMounted) {
            setAllQuestions(getQuestionsRes.data.data);
            setLoadingQuestions(false);
          }
        }
      } catch (error) {
        setLoadingQuestions(false);
        console.log(error);
      }
    };
    getQuestions();

    return () => {
      isMounted = false;
    };
  }, [fetchCount, selectedFilter]);

  useEffect(() => {
    let isMounted = true;

    if (allQuestions) {
      const mappedQuestions = allQuestions.map((ques) => ({
        ...ques,
        key: ques.id
      }));

      if (isMounted) {
        setQuestionsDataSource(mappedQuestions);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allQuestions, allQuestions.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 10 }}
      className="questions-table"
      dataSource={questionsDataSource}
      columns={questionsColumns(
        user?.token,
        setFetchCount,
        setLoadingQuestions,
        setQuestionModalOpened,
        setSelectedQuestionId
      )}
      loading={loadingQuestions}
      scroll={{ x: 1200 }}
    />
  );
};

export default QuestionsTable;
