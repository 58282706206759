/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import * as yup from 'yup';
import { store } from 'react-notifications-component';

import { Button, Form, Popconfirm, Tooltip } from 'antd';
import { EditOutlined, SendOutlined, DeleteOutlined } from '@ant-design/icons';

import deleteProjectImage from '../../apis/project-api/deleteProjectImage';
import addProjectTechnoloty from '../../apis/project-api/addProjectTechnoloty';
import UploadProjectFile from './UploadProjectFile';

const schema = yup.object().shape({
  image_project: yup.mixed().required('من فضلك اختار صورة التقنيه')
});

const defaultValues = {
  image_project: null
};

const AddProjectTechnology = (count, project, setcategory, setCount) => {
  const renderScreens = () => {
    return count.project.technology.map(({ id, image }) => (
      <div key={id} className="img-delete-wrap">
        <Popconfirm
          title="هل أنت متأكد من حذف تلك الصوره"
          onConfirm={(key) => deleteImage(id)}
          okText="نعم"
          cancelText="لا">
          <Tooltip title="حذف الصوره">
            <Button
              className="delete-img-btn"
              size="large"
              type="dashed"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
          {/* <button>حذف</button> */}
        </Popconfirm>

        <img src={image} alt={id} />
      </div>
    ));
  };

  useEffect(() => {
    reset({ ...defaultValues });
  }, []);

  const [urls, setUrls] = useState([]);

  const { user } = useContext(UesrContext);
  const {
    setValue,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  console.log(count);
  const deleteImage = async (id) => {
    try {
      const fetchedData = await deleteProjectImage(id, user?.token);
      if (!fetchedData) {
        store.addNotification({
          title: 'حدث خطأ اثناء حذف صوره تقنيه',
          message: fetchedData?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم حذف صوره تقنيه  المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        // console.log(fetchedData.data);
        //   console.log(fetchedData);
        count.setCount(count.count + 1);
        count.setcategory(fetchedData.data);
        reset({ ...defaultValues });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // deleteProjectImage
  const onSubmit = async (data) => {
    try {
      const fetchedData = await addProjectTechnoloty(data, count.project.key, user?.token);
      if (!fetchedData) {
        store.addNotification({
          title: 'حدث خطأ اثناء اضافه صوره تقنيه',
          message: fetchedData?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم اضافه صوره تقنيه  المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        // console.log(fetchedData.data);

        count.setCount(count.count + 1);
        count.setcategory(fetchedData.data);
        reset({ ...defaultValues });
        setUrls([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  return (
    <div className="image-project">
      <div className="add-img">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          className="add-project-form">
          <UploadProjectFile
            accept="image/png, image/jpg, image/jpeg, image/gif"
            maxFiles={1}
            name="image_project"
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            multiple={false}
            setUrls={setUrls}
            urls={urls}
            label={
              <>
                <EditOutlined />
                أضافه صوره للتقنيه
              </>
            }
            errorMsg={errors?.image_project ? errors?.image_project?.message : ''}
          />
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            icon={<SendOutlined />}
            loading={isSubmitting}>
            اضافة صوره للتقنيه
          </Button>
        </Form>
      </div>
      {renderScreens()}
    </div>
  );
};

export default AddProjectTechnology;
