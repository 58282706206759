import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { UserOutlined, LoginOutlined, LockOutlined } from '@ant-design/icons';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import AntdCheckbox from '../../common/antd-form-components/AntdCheckbox';
import { signinApi } from '../../apis/auth/signin';
import { useTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';
import Logo from '../../common/logo/Logo';
import './LoginForm.scss';
import UesrContext from '../../contexts/user-context/UserProvider';

const schema = Yup.object().shape({
  name: Yup.string().required('من فضلك ادخل اســمك'),
  password: Yup.string().required('من فضلك ادخل الرقم السرى')
});

const LoginForm = () => {
  const { i18n } = useTranslation();
  const { setCurrentUser, setUserToState } = useContext(UesrContext);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      password: '',
      remember: true
    }
  });
  const onSubmit = async (data) => {
    try {
      const res = await signinApi(data, i18n.language);
      console.log('res: ', res);
      if (res.status === 200 && res.data.status === 0) {
        store.addNotification({
          title: 'حدث خطأ اثناء الدخول',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.status === 200 && res.data.status === 1) {
        if (data.remember) setCurrentUser({ ...res.data.data });
        else setUserToState({ ...res.data.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  return (
    <Form className="login-form" form={form} layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <div className="form-header">
        <Logo className="form-logo" />
        <p>تسجيل الدخول</p>
      </div>

      <div className="form-body">
        <AntdTextField
          name="name"
          type="text"
          placeholder={'الاســـــم...'}
          // label="الاســــم"
          errorMsg={errors?.name?.message}
          validateStatus={errors?.name ? 'error' : ''}
          prefix={<UserOutlined />}
          control={control}
        />
        <AntdTextField
          name="password"
          type="password"
          placeholder={'الرقــم الســرى...'}
          // label="الرقــم الســرى"
          errorMsg={errors?.password?.message}
          validateStatus={errors?.password ? 'error' : ''}
          prefix={<LockOutlined />}
          control={control}
        />

        <AntdCheckbox name="remember" label="تذكرنى" control={control} />
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<LoginOutlined />}
          loading={isSubmitting}>
          تسجيل الدخول
        </Button>
      </div>
    </Form>
  );
};

export default LoginForm;
