/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import './AddServiceForm.scss';
import UploadServiceFile from './UploadServiceFile';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import createService from '../../apis/services/createService';
import UesrContext from '../../contexts/user-context/UserProvider';
import ServicesContext from '../../contexts/services-context/ServicesProvider';

const defaultValues = {
  name_ar: '',
  name_en: '',
  desc_ar: '',
  desc_en: '',
  service_imgs: null,
  status: '',
  type: '', //  1  <=== service , 2 <=== gallery
  is_question: '' // 1 <== Yes, 2 <=== No
};

const AddServiceForm = () => {
  const [descArEditorState, setDescArEditorState] = useState(EditorState.createEmpty());
  const [descEnEditorState, setDescEnEditorState] = useState(EditorState.createEmpty());

  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setServiceModalOpened,
    allServices,
    setSelectedServId,
    selectedServId,
    selectedService,
    setSelectedService
  } = useContext(ServicesContext);

  let schema;
  if (selectedService && selectedServId) {
    schema = Yup.object().shape({
      name_ar: Yup.string().required('من فضلك ادخل الخدمة باللغة العربية'),
      name_en: Yup.string().required('من فضلك ادخل الخدمة باللغة الانجليزية'),
      desc_ar: Yup.string().test('desc_ar', 'من فضلك ادخل تفاصيل الخدمة باللغة العربية', (v) => {
        let result = true;
        if (!v || v === '<p><br></p>') {
          result = false;
        }
        return result;
      }),
      desc_en: Yup.string().test('desc_en', 'من فضلك ادخل تفاصيل الخدمة باللغة الانجليزية', (v) => {
        let result = true;
        if (!v || v === '<p><br></p>') {
          result = false;
        }
        return result;
      }),
      status: Yup.string().required('من فضلك اختار حالة الخدمة'),
      type: Yup.string().required('من فضلك اختــار نــوع الخدمــة'),
      is_question: Yup.string().required(
        'من فضلك اختــار هل يجب ان تظهر هذه الخدمة مع الاسئلة ام لأ'
      )
    });
  } else {
    schema = Yup.object().shape({
      name_ar: Yup.string().required('من فضلك ادخل الخدمة باللغة العربية'),
      name_en: Yup.string().required('من فضلك ادخل الخدمة باللغة الانجليزية'),
      desc_ar: Yup.string().test('desc_ar', 'من فضلك ادخل تفاصيل الخدمة باللغة العربية', (v) => {
        let result = true;
        if (!v || v === '<p><br></p>') {
          result = false;
        }
        return result;
      }),
      desc_en: Yup.string().test('desc_en', 'من فضلك ادخل تفاصيل الخدمة باللغة الانجليزية', (v) => {
        let result = true;
        if (!v || v === '<p><br></p>') {
          result = false;
        }
        return result;
      }),
      service_imgs: Yup.mixed().required('من فضلك اختار صورة الخدمة'),
      status: Yup.string().required('من فضلك اختار حالة الخدمة'),
      type: Yup.string().required('من فضلك اختــار نــوع الخدمــة'),
      is_question: Yup.string().required(
        'من فضلك اختــار هل يجب ان تظهر هذه الخدمة مع الاسئلة ام لأ'
      )
    });
  }

  useEffect(() => {
    if (selectedServId) {
      const foundService = allServices.find((serv) => serv.key === selectedServId);
      if (foundService) setSelectedService(foundService);
    }
  }, [selectedServId]);

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    if (selectedService?.image) {
      console.log('selected service: ', selectedService);
      setUrls([selectedService.image]);
    }
  }, [selectedService]);

  const {
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  console.log('errros: ', errors);
  console.log('watch: ', watch('service_imgs'));

  const onSubmit = async (data) => {
    try {
      if (!getValues()?.desc_ar || getValues()?.desc_ar === '<p><br></p>') {
        setError('desc_ar', {
          type: 'required',
          message: 'من فضلك ادخل تفاصيل الخدمة باللغة العربية'
        });
      } else {
        // setError('desc_ar', )
        clearErrors('desc_ar');
      }

      let res;
      if (selectedService) {
        res = await createService(`/Service/update/${selectedService.key}`, data, user?.token);
      } else {
        res = await createService('/Service/create', data, user?.token);
      }
      // console.log('create service response: ', res);
      if (res?.status === 200 && res?.data?.status === 0) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء الخدمة',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.status === 1) {
        reset({ ...defaultValues });
        setValue('desc_ar', '');
        setValue('desc_en', '');
        setDescArEditorState(EditorState.createEmpty());
        setDescEnEditorState(EditorState.createEmpty());
        setServiceModalOpened(false);
        setSelectedServId('');
        setSelectedService(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء الخدمة المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    setValue('desc_ar', stateToHTML(descArEditorState.getCurrentContent()));
  }, [stateToHTML(descArEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('desc_en', stateToHTML(descEnEditorState.getCurrentContent()));
  }, [stateToHTML(descEnEditorState.getCurrentContent())]);

  // handle initial values
  useEffect(() => {
    if (selectedService && selectedServId) {
      const { name_ar, name_en, desc_ar, desc_en, status, type, is_question } = selectedService;
      setValue('name_ar', name_ar);
      setValue('name_en', name_en);
      setValue('desc_ar', desc_ar);
      setValue('desc_en', desc_en);
      setValue('status', String(status));
      setValue('type', String(type));
      setValue('is_question', String(is_question));

      const contentBlockDescAr = htmlToDraft(desc_ar);
      if (contentBlockDescAr) {
        const contentState = ContentState.createFromBlockArray(contentBlockDescAr.contentBlocks);
        const editorStateDescAr = EditorState.createWithContent(contentState);
        setDescArEditorState(editorStateDescAr);
      }
      const contentBlockDescEn = htmlToDraft(desc_en);
      if (contentBlockDescEn) {
        const contentState = ContentState.createFromBlockArray(contentBlockDescEn.contentBlocks);
        const editorStateDescEn = EditorState.createWithContent(contentState);
        setDescEnEditorState(editorStateDescEn);
      }

      // convertPathToFile(image).then((file) => {
      //   console.log(file);
      //   setValue('service_imgs', [file]);
      // });
    }
  }, [selectedService, selectedServId]);

  return (
    <Form
      className="add-service-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <AntdTextField
          name="name_ar"
          type="text"
          label="اســم الخدمة باللغة العربية"
          placeholder="اســم الخدمة باللغة العربية..."
          errorMsg={errors?.name_ar?.message}
          validateStatus={errors?.name_ar ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
          formClassName="add-service-form"
        />
        <AntdTextField
          name="name_en"
          type="text"
          label="اســم الخدمة باللغة الانجليزية"
          placeholder="اســم الخدمة باللغة الانجليزية..."
          errorMsg={errors?.name_en?.message}
          validateStatus={errors?.name_en ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            تفاصيل الخدمة باللغة العربية
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={descArEditorState}
            onEditorStateChange={(v) => {
              setDescArEditorState(v);
              if (!getValues()?.desc_ar || getValues()?.desc_ar === '<p><br></p>') {
                setError('desc_ar', {
                  type: 'required',
                  message: 'من فضلك ادخل تفاصيل الخدمة باللغة العربية'
                });
              } else {
                // setError('desc_ar', )
                clearErrors('desc_ar');
              }
            }}
          />
          <p className="error-p">{errors?.desc_ar ? errors?.desc_ar.message : ''}</p>
        </div>

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            تفاصيل الخدمة باللغة الانجليزية
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={descEnEditorState}
            // onBlur={(v) => console.log(v)}
            onEditorStateChange={(v) => {
              setDescEnEditorState(v);
              if (!getValues()?.desc_en || getValues()?.desc_en === '<p><br></p>') {
                setError('desc_en', {
                  type: 'required',
                  message: 'من فضلك ادخل تفاصيل الخدمة باللغة الانجليزية'
                });
              } else {
                // setError('desc_en', )
                clearErrors('desc_en');
              }
            }}
          />
          <p className="error-p">{errors?.desc_en ? errors?.desc_en.message : ''}</p>
        </div>

        <UploadServiceFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="service_imgs"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              صورة الخدمة
            </>
          }
          urls={urls}
          errorMsg={errors?.service_imgs ? errors?.service_imgs?.message : ''}
        />

        <AntdSelectOption
          name="status"
          placeholder="اســم الخدمة باللغة العربية..."
          errorMsg={errors?.status?.message}
          validateStatus={errors?.status ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'نشط', value: '1' },
            { title: 'غير نشط', value: '2' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              حالة الخدمــة
            </p>
          }
          formClassName="add-service-form"
        />

        <AntdSelectOption
          name="type"
          placeholder="نوع الخدمـــة..."
          errorMsg={errors?.type?.message}
          validateStatus={errors?.type ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'خدمـــة', value: '1' },
            { title: 'معرض أعمــال', value: '2' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              نـــوع الخدمــة
            </p>
          }
          formClassName="add-service-form"
        />

        <AntdSelectOption
          name="is_question"
          placeholder="اظهر الخدمة مع الاسئلة؟..."
          errorMsg={errors?.is_question?.message}
          validateStatus={errors?.is_question ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'نعـــم', value: '1' },
            { title: 'لا', value: '2' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              هل يجب ان تظهر هذه الخدمة ضمن الاسئلة ام لا
            </p>
          }
          formClassName="add-service-form"
        />

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافة الخدمة
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddServiceForm;
