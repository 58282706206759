import React from 'react';
import './FatorahPage.scss';
// import FatorahContext from '../../contexts/fatorah-context/FatorahProvider';
import FatorahTable from './FatorahTable';

const FatorahPage = () => {
  //   const { fatorahModalOpened, setFatorahModalOpened } = useContext(FatorahContext);

  return (
    <div className="fatorah-page">
      {/* <Button
        onClick={() => setEmailsModalOpened(true)}
        className="new-btn"
        type="primary"
        icon={<PlusOutlined />}>
        <span className="btn-text">رســالة جديدة</span>
      </Button> */}

      <FatorahTable />
    </div>
  );
};

export default FatorahPage;
