/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const UploadProfileFile = (props) => {
  const { name, label, accept, errorMsg, register, unregister, setValue, multiple } = props;

  // const [myDroppedFiles, setMyDroppedFiles] = useState([]);
  // const [props.urls, props.setUrls] = useState([]);
  // const onDrop = useCallback(
  //   (droppedFiles) => {
  //     setValue(name, droppedFiles, { shouldValidate: true });
  //     // setMyDroppedFiles(droppedFiles);

  //     // read file
  //     const reader = new FileReader();
  //     reader.addEventListener('load', () => {
  //       props.setUrls([reader.result]);
  //     });
  //     reader.readAsDataURL(droppedFiles[0]);
  //   },
  //   [name]
  // );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    // onDrop,
    accept: accept,
    multiple
  });

  //console.log('accepted files: ', acceptedFiles);

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      setValue(name, acceptedFiles, { shouldValidate: true });

      // read file
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        props.setUrls([reader.result]);
      });
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, [acceptedFiles, name]);
  //console.log('accepted files: ', acceptedFiles);

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  //console.log('selected project: ', props.selectedProject);

  useEffect(() => {
    if (props.selectedProject && props.selectedProject.image) {
      props.setUrls([props.selectedProject.image]);
    } else {
      //console.log('not selected');
      props.setUrls([]);
    }
  }, [props.selectedProject]);

  return (
    <div className="upload-files-wrapper">
      <label className="file-input-label" htmlFor={name}>
        {label}
      </label>
      <div className="dropzone-itself" {...getRootProps()}>
        <input
          name={name}
          lbale={label}
          accept={accept}
          className="file-input-itself"
          id={name}
          {...getInputProps()}
        />
        <div className={'dashed-border' + (isDragActive ? 'gray-500-bg' : 'gray-200-bg')}>
          {!props.urls?.length && (
            <p className="text-center my-2">اسحب صورة المستخدم وضعها هنا ...</p>
          )}
          {props.urls?.length > 0 && (
            <div className="imgs-preview-wrapper">
              {props.urls.map((url, index) => {
                return (
                  <div className="img-preview" key={index}>
                    <img src={url} alt="preview" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {errorMsg && <p className="error-p">{errorMsg}</p>}
    </div>
  );
};

export default UploadProfileFile;
