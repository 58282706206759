import { store } from 'react-notifications-component';
import { Button, Popconfirm, Tooltip } from 'antd';
import deleteEmailApi from '../../apis/emails-apis/deleteEmailApi';
import { DeleteOutlined } from '@ant-design/icons';

const newsLetterTableColumns = (token, setFetchCount, setLoadingEmails) => {
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingEmails(true);
      const res = await deleteEmailApi(key, token);
      if (res.status === 200 && res.data.status === 1) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingEmails(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الخدمة بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingEmails(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingEmails(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  return [
    {
      title: '#',
      dataIndex: 'index',
      width: 62
    },
    {
      title: 'البريد الاكترونى',
      dataIndex: 'email',
      width: 132
    },
    {
      title: 'حذف البريد الاكترونى',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف البريد الاكترونى؟</p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record.key)}>
            <Tooltip title="حذف البريد الاكترونى؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default newsLetterTableColumns;
