/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { MainAppBarProvider } from "../../contexts/main-app-bar-context/MainAppBarProvider";
import Loading from '../../common/loading/Loading';
import Routes from './Routes';
import '../../i18n';
import AppLayout from './Layout';
import { useEffect } from 'react';
import axios from 'axios';
import { ConfigProvider } from 'antd';
import LoginPage from '../../pages/login-page/LoginPage';
import routerLinks from './routerLinks';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import UesrContext from '../../contexts/user-context/UserProvider';
import ReactNotification from 'react-notifications-component';

axios.defaults.baseURL = 'https://api.codein.sa/apiAdmin';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  const { loggedIn } = useContext(UesrContext);
  return (
    <div className={`app app-${i18n.dir()}`}>
      <Suspense fallback={<Loading />}>
        <ConfigProvider direction={i18n.dir()}>
          <ReactNotification className={i18n.dir()} />
          <Switch>
            {!loggedIn ? (
              <Route exact path={routerLinks.loginPage}>
                <LoginPage />
              </Route>
            ) : (
              <AppLayout>
                <Routes />
              </AppLayout>
            )}
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </ConfigProvider>
      </Suspense>
    </div>
  );
}

export default App;
