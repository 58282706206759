import React, { useContext } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import AddServiceForm from './AddServiceForm';
import ServicesTable from './ServicesTable';
import ServicesContext from '../../contexts/services-context/ServicesProvider';

import './ServicesPage.scss';

const ServicesPage = () => {
  const { serviceModalOpened, setServiceModalOpened, setSelectedServId, setSelectedService } =
    useContext(ServicesContext);

  return (
    <div className="services-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setServiceModalOpened(true)}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة خدمة</span>
        </Button>

        {/* <ServicesFilter /> */}
      </div>

      <ServicesTable />

      {serviceModalOpened && (
        <Modal
          className="add-service-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={setSelectedServId ? 'تعديل الخــدمة' : 'اضـافة خدمة جديدة'}
          visible={serviceModalOpened}
          onOk={() => {
            setServiceModalOpened(false);
            setSelectedServId('');
            setSelectedService(null);
          }}
          onCancel={() => {
            setServiceModalOpened(false);
            setSelectedServId('');
            setSelectedService(null);
          }}
          footer={false}>
          <AddServiceForm />
        </Modal>
      )}
    </div>
  );
};

export default ServicesPage;
