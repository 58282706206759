import React from 'react';
const AnswersDataRender = ({ answers }) => {
  const constructClassName = (type) => {
    if (String(type) === '3' || String(type) === '4') return 'placeholder-span radio-span';
    else if (String(type) === '5') return 'placeholder-span checkbox-span';
    return '';
  };

  const renderChooses = (type, chooses) => {
    // 1 <-- text field
    // 2 <-- file upload
    // 3 <-- select option
    // 4 <-- radio buttons
    // 5 <-- check boxs
    return chooses.map((c, index) => (
      <div key={index} className="choose-item">
        {c?.name && (
          <>
            <span className={constructClassName(type)}></span>
            <span className="n-span">{c.name}</span>
          </>
        )}
      </div>
    ));
  };

  const renderPersonAnswers = (type, answer) => {
    if (String(type) === '1') {
      return <p className="answer-p"> - {answer}</p>;
    } else if (String(type) === '6') {
      return <p className="answer-p"> - {answer}</p>;
    } else if (String(type) === '2') {
      return <img src={answer} alt="answer" />;
    } else if (String(type) === '3' || String(type) === '4' || String(type) === '5') {
      return (
        <div className="choose-spans">
          {answer?.length > 0 &&
            answer.map(
              (a, index) =>
                a?.name && (
                  <span className="person-choose-span" key={index}>
                    {a.name}
                  </span>
                )
            )}
        </div>
      );
    }
    return '';
  };

  const renderAsnwers = () => {
    return (
      answers?.length > 0 &&
      answers.map(({ id, answer, question }, index) => {
        return (
          <li className="main-answer-li" key={id}>
            <div className="question-wrap">
              {question?.name && (
                <div className="question-title">
                  {index + 1} . {question.name}
                </div>
              )}
              {/* {question?.is_required && String(question.is_required) === '1' ? (
                <Tag color={'orange'}>السؤال اجبارى</Tag>
              ) : (
                <Tag color={'success'}>السؤال غير اجبارى</Tag>
              )} */}
              {question?.question_chooses?.length > 0 && (
                <div className="question-chooses">
                  {renderChooses(question.type, question.question_chooses)}
                </div>
              )}
            </div>

            <div className="q-a-separator">
              <span></span>
              <p className="a-text">الاجـــابة</p>
              <span></span>
            </div>

            {answer && (
              <div className="person-answers">{renderPersonAnswers(question.type, answer)}</div>
            )}
          </li>
        );
      })
    );
  };

  return (
    <div className="answers-data">
      <ul className="answers-data-ul">{renderAsnwers()}</ul>
    </div>
  );
};

export default AnswersDataRender;
