import React, { useContext } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import QuestionsTable from './QuestionsTable';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';
import AddQuestionForm from './AddQuestionsForm';

import './QuestionsPage.scss';
import QuestionsCategoryFilter from './QuestionsCategoryFilter';

const QuestionsPage = () => {
  const {
    questionModalOpened,
    setQuestionModalOpened,
    setSelectedQuestionId,
    setSelectedQuestion
  } = useContext(QuestionsContext);

  return (
    <div className="questions-page">
      <div className="button-filter-wrap">
        <Button
          onClick={() => setQuestionModalOpened(true)}
          className="add-question-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة سؤال جديد</span>
        </Button>
        <QuestionsCategoryFilter />
      </div>
      <QuestionsTable />

      {questionModalOpened && (
        <Modal
          className="add-service-modal"
          width="90%"
          style={{ maxWidth: '1052px' }}
          title="اضافة سؤال جديد"
          visible={questionModalOpened}
          onOk={() => {
            setQuestionModalOpened(false);
            setSelectedQuestionId('');
            setSelectedQuestion(null);
          }}
          onCancel={() => {
            setQuestionModalOpened(false);
            setSelectedQuestionId('');
            setSelectedQuestion(null);
          }}
          footer={false}>
          <AddQuestionForm />
        </Modal>
      )}
    </div>
  );
};

export default QuestionsPage;
