/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import UesrContext from '../../contexts/user-context/UserProvider';
import './FatorahPage.scss';
import fatorahTableColumns from './fatorahTableColumns';
// import FatorahContext from '../../contexts/fatorah-context/FatorahProvider';
import getAllFatorah from '../../apis/fatorah-apis/getAllFatorah';
import { store } from 'react-notifications-component';

const FatorahTable = () => {
  const [contact, setcontact] = useState([]);
  const [count, setcount] = useState([]);
  const [loading, setloading] = useState(false);

  const { user } = useContext(UesrContext);

  useEffect(() => {
    const getContactCall = async () => {
      try {
        setloading(true);
        const fetchedData = await getAllFatorah(user?.token);
        console.log(fetchedData);
        if (!fetchedData) {
          setloading(false);
        } else {
          setloading(false);

          setcontact(fetchedData);
        }
      } catch (error) {
        setloading(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        console.log(error);
      }
    };
    getContactCall();
  }, [user?.token, count]);
  return (
    <div>
      <Table
        columns={fatorahTableColumns(count, setcount, user?.token)}
        dataSource={contact}
        loading={loading}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default FatorahTable;
