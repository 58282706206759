import React, { useContext } from 'react';
import { Modal } from 'antd';
import AnswersTable from './AnswersTable';
import AnswersContext from '../../contexts/answers-context/AnswersProvider';
import './AnswersPage.scss';
import AnswersModalContent from './AnswersModalContent';

const AnswersPage = () => {
  const { answerModalOpened, setAnswerModalOpened, setSelectedAsnswerId, setSelecteAnswer } =
    useContext(AnswersContext);

  return (
    <div className="answers-page">
      <AnswersTable />

      {/* {answerModalOpened && ( */}
      <Modal
        className="add-service-modal"
        width="90%"
        style={{ maxWidth: '1200px' }}
        title="تفاصيل الطـــلب المرســـل"
        visible={answerModalOpened}
        onOk={() => {
          setAnswerModalOpened(false);
          setSelectedAsnswerId('');
          setSelecteAnswer(null);
        }}
        onCancel={() => {
          setAnswerModalOpened(false);
          setSelectedAsnswerId('');
          setSelecteAnswer(null);
        }}
        footer={false}>
        <AnswersModalContent />
      </Modal>
      {/* )} */}
    </div>
  );
};

export default AnswersPage;
